import { inventoryService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryInventory(inventoryId: number) {
	return useQuery({
		queryKey: ['Inventory', inventoryId],
		queryFn: () => inventoryService.getInventory(inventoryId),
		enabled: !!inventoryId,
		refetchOnWindowFocus: false
	});
}
