import { authService } from '@services/index';
import axios, { AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	headers: {
		'Content-Type': 'application/json'
	}
});

const excludedUrls: string[] = ['/links/reset-link/'];

axiosInstance.interceptors.request.use(
	(config) => {
		if (config.url && !excludedUrls.includes(config.url)) {
			if (authService.getLocalToken() && config.headers) {
				// eslint-disable-next-line no-param-reassign
				config.headers.Authorization = `Bearer ${authService.getLocalToken()}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const { response } = error;

		if (response.status === 401 || response.status === 403) {
			authService.logout();
			window.location.href = '/login?expired=true';
			return Promise.resolve();
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
