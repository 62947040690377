import { IScheduleDto } from '@interfaces/scheduleInterface';
import { scheduleService } from '@services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationScheduleUpdate() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ scheduleDto, scheduleId }: { scheduleDto: IScheduleDto; scheduleId: number }) =>
			scheduleService.updateSchedule(scheduleId, scheduleDto),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['Schedules'] });
		}
	});
}
