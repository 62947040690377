import { IIcon } from './types';

const IconEmpty = ({ height = 24, width = 24 }: IIcon) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
		<path fill="#001A72" d="M21 18v-6h-5l-2 3h-4l-1.5-3H3v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2Z" opacity=".15" />
		<path
			stroke="#001A72"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M3 12v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6M3 12l2.757-7.351A1 1 0 0 1 6.693 4h10.614a1 1 0 0 1 .936.649L21 12M3 12h5.5l1.5 3h4l2-3h5"
		/>
	</svg>
);

export default IconEmpty;
