import { QueryParametersUsers } from '@interfaces/userInterface';
import { userService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryUsers({ page, perPage, fullName, role }: QueryParametersUsers) {
	return useQuery({
		queryKey: ['Users', page, perPage, fullName, role],
		queryFn: () => userService.getUsers({ page, perPage, fullName, role }),
		enabled: !!page,
		refetchOnWindowFocus: false
	});
}
