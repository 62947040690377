export const generateRoleLabel = (roleName: string) => {
	const roles: { [key: string]: { label: string; color: string } } = {
		admin: { label: 'Administrateur', color: 'magenta' },
		responsable: { label: 'Responsable', color: 'volcano' },
		cashier: { label: 'Caissier', color: 'green' },
		operator: { label: 'Opérateur', color: 'blue' },
		controller: { label: 'Contrôleur', color: 'purple' },
		store: { label: 'Confiserie', color: 'orange' },
		inventory: { label: 'Affiches', color: 'cyan' }
	};
	return roles[roleName];
};
