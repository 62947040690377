import { movieService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryMovieTMDB({
	movieTMDBId,
	config
}: {
	movieTMDBId: number;
	config?: { enabled: boolean };
}) {
	return useQuery({
		queryKey: ['MovieTMDB'],
		queryFn: () => movieService.getMovieTMDB(movieTMDBId),
		enabled: !!movieTMDBId,
		refetchOnWindowFocus: false,
		...config
	});
}
