import { useEffect, useState } from 'react';

import { Empty, MovieItem, Search, Skeleton, Tabs } from '@components/index';
import { Container, Header, Subheader } from '@elements/index';
import useMutationDeleteInventory from '@hooks/inventory/useMutationDeleteInventory';
import useQueryInventories from '@hooks/inventory/useQueryInventories';
import IconMovieCards from '@icons/IconMovieCards';
import IconPlusCircle from '@icons/IconPlusCircle';
import { PosterFilter } from '@interfaces/inventoryInterface';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Button, Pagination, Popconfirm, message } from 'antd';
import { useNavigate } from 'react-router-dom';

const Inventory = () => {
	const navigate = useNavigate();
	const isSmDevice = useMediaQuery('only screen and (max-width : 768px)');

	const [search, setSearch] = useState<string>('');
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(20);
	const [posterFilter, setPosterFilter] = useState<PosterFilter>(PosterFilter.ALL);

	const { data: inventories, isLoading: isLoadingInventories } = useQueryInventories({
		page,
		pageSize,
		search,
		posterFilter
	});
	const { mutate: mutateDeleteInventory, isSuccess: isSuccessDeleteInventory } = useMutationDeleteInventory();

	const onAddInventory = () => {
		navigate('/inventory/add');
	};

	const onDeleteHandler = (inventoryId: number) => {
		mutateDeleteInventory(inventoryId);
	};

	const onHandlerSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setSearch((event.target as HTMLInputElement).value);
			setPage(1);
		}
	};

	const onSearch = (valueToSearch: string) => {
		setSearch(valueToSearch);
		setPage(1);
	};

	const onHandlerPosterFilter = (posterFilterChanged: PosterFilter) => {
		setPosterFilter(posterFilterChanged);
		localStorage.setItem('posterFilter', posterFilterChanged);
	};

	const onHandlerPageChange = (pageChanged: number, pageSizeChanged: number) => {
		setPage(pageChanged);
		setPageSize(pageSizeChanged);
		localStorage.setItem('page', pageChanged.toString());
	};

	const onHandlerPageSizeChange = (current: number, size: number) => {
		setPageSize(size);
		localStorage.setItem('pageSize', size.toString());
	};

	useEffect(() => {
		if (isSuccessDeleteInventory) {
			message.success('Le film a été correctement supprimé.');
		}
	}, [isSuccessDeleteInventory]);

	useEffect(() => {
		const localPosterFilter = localStorage.getItem('posterFilter');
		const localPage = localStorage.getItem('page');

		if (localPosterFilter) {
			setPosterFilter(localPosterFilter as PosterFilter);
		}

		if (localPage) {
			setPage(Number(localPage));
		}
	}, []);

	return (
		<>
			<Header />
			<Container>
				<Subheader
					title="Affiches"
					controls={
						<Button type="primary" htmlType="button" onClick={() => onAddInventory()}>
							<IconPlusCircle height={16} width={16} className="mr-1" /> Ajouter un film
						</Button>
					}
				/>

				<div className="flex flex-col gap-4">
					<Search
						onKeyDown={(event) => onHandlerSearchKeyDown(event)}
						placeholder="Rechercher un film"
						onSearch={(value) => onSearch(value)}
						onClear={() => setSearch('')}
						value={search}
					/>

					<div className="flex justify-center md:justify-start">
						<Tabs className="flex">
							<Tabs.Item
								isActive={posterFilter === PosterFilter.ALL}
								onClick={() => onHandlerPosterFilter(PosterFilter.ALL)}>
								Voir toutes
							</Tabs.Item>
							<Tabs.Item
								isActive={posterFilter === PosterFilter.EMPTY}
								onClick={() => onHandlerPosterFilter(PosterFilter.EMPTY)}>
								Sans image
							</Tabs.Item>
						</Tabs>
					</div>

					{inventories && inventories.sum && (
						<div>
							<div className="flex gap-2">
								<div className="min-w-[100px] rounded-lg bg-white p-2">
									<div className="text-2xl font-bold">
										{Number(inventories.sum.posterCount).toLocaleString()}
									</div>
									<small>Affiches</small>
								</div>
								<div className="min-w-[100px] rounded-lg bg-white p-2">
									<div className="text-2xl font-bold">
										{Number(inventories.sum.posterSmallCount).toLocaleString()}
									</div>
									<small>Affichettes</small>
								</div>
								{Number(inventories.sum.withoutPosterPathCount) > 0 && (
									<div className="min-w-[100px] rounded-lg bg-white p-2">
										<div className="text-2xl font-bold">
											{Number(inventories.sum.withoutPosterPathCount).toLocaleString()}
										</div>
										<small>Sans image</small>
									</div>
								)}
							</div>
						</div>
					)}

					{isLoadingInventories && (
						<div className="flex gap-2">
							<Skeleton height={72} width={100} />
							<Skeleton height={72} width={100} />
							<Skeleton height={72} width={100} />
						</div>
					)}

					{inventories && inventories.items.length > 0 && (
						<div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
							{inventories.items.map((inventory) => (
								<div key={inventory.id} className="rounded-lg bg-white p-4">
									<div className="grid grid-cols-5 gap-3 md:grid-cols-5">
										<div className="col-span-3 flex md:col-span-2">
											<MovieItem
												height={isSmDevice ? 300 : 200}
												posterPath={inventory.poster_path}
												originalTitle={inventory.title}
											/>
										</div>
										<div className="col-span-2 flex flex-col justify-between gap-4 md:col-span-3">
											<div className="font-bold">{inventory.title}</div>
											<div className="flex flex-col justify-between gap-4 md:flex-row">
												<div className="flex w-[80px] flex-col items-center justify-center rounded-lg bg-slate-200 p-2">
													<div className="text-center text-2xl font-bold">{inventory.posterCount}</div>
													<small>{inventory.posterCount <= 1 ? 'Affiche' : 'Affiches'}</small>
												</div>
												<div className="flex w-[80px] flex-col items-center justify-center rounded-lg bg-slate-200 p-2">
													<div className="text-center text-2xl font-bold">
														{inventory.posterSmallCount}
													</div>
													<small>{inventory.posterSmallCount <= 1 ? 'Affichette' : 'Affichettes'}</small>
												</div>
											</div>
											<div className="flex flex-col justify-end gap-2 md:flex-row">
												<Button
													size="small"
													type="default"
													onClick={() => {
														navigate(`/inventory/edit/${inventory.id}`);
													}}>
													Editer
												</Button>

												<Popconfirm
													title="Eliminer ce film"
													description="Souhaitez-vous éliminer ce film?"
													onConfirm={() => {
														onDeleteHandler(inventory.id);
													}}
													onCancel={() => {}}
													okText="Oui, supprimer"
													cancelText="Non">
													<Button danger ghost size="small" type="primary">
														Supprimer
													</Button>
												</Popconfirm>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					)}

					{isLoadingInventories && (
						<div className="grid grid-cols-3 flex-col gap-2">
							<Skeleton height={289} width="100%" />
							<Skeleton height={289} width="100%" />
							<Skeleton height={289} width="100%" />
							<Skeleton height={289} width="100%" />
						</div>
					)}

					{inventories && inventories.items.length === 0 && (
						<div className="my-6 flex flex-col items-center justify-center gap-4">
							<Empty
								icon={<IconMovieCards />}
								title="Aucun film trouvé"
								description={`Nous n'avons pas trouvé le film "${search}"`}
							/>
							<Button type="primary" onClick={() => setSearch('')}>
								Réinitialiser la recherche
							</Button>
						</div>
					)}

					{inventories && inventories.items && inventories.meta.totalItems > pageSize && (
						<div className="my-6 flex justify-end">
							<Pagination
								onShowSizeChange={(current, size) => onHandlerPageSizeChange(current, size)}
								current={page}
								total={inventories.meta.totalItems}
								pageSize={pageSize}
								onChange={(pageChanged, pageSizeChanged) => {
									onHandlerPageChange(pageChanged, pageSizeChanged);
								}}
							/>
						</div>
					)}
				</div>
			</Container>
		</>
	);
};

export default Inventory;
