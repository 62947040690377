import React from 'react';

import { format } from 'date-fns';
import { CaptionProps, useNavigation } from 'react-day-picker';
import { ChevronLeft, ChevronRight } from 'react-feather';

export const HeaderDatePicker = ({ displayMonth }: CaptionProps) => {
	const { goToMonth, nextMonth, previousMonth } = useNavigation();
	return (
		<div className="flex select-none items-center justify-between">
			<button
				type="button"
				className="flex items-center rounded-lg border p-1 font-semibold"
				disabled={!previousMonth}
				onClick={() => previousMonth && goToMonth(previousMonth)}>
				<ChevronLeft />
			</button>
			<div className="text-base font-bold">{format(displayMonth, 'MMM yyy')}</div>
			<button
				type="button"
				className="flex items-center rounded-lg border p-1 font-semibold"
				disabled={!nextMonth}
				onClick={() => nextMonth && goToMonth(nextMonth)}>
				<ChevronRight />
			</button>
		</div>
	);
};
