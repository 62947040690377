import { IMovie } from './movieInterface';
import { ISchedule } from './scheduleInterface';
import { Suggestion } from './suggestionInterface';
import { IUser } from './userInterface';

export interface INotificationData {
	movie?: IMovie;
	schedule?: ISchedule;
	suggestion?: Suggestion;
	userToSuggest?: IUser;
}

export enum NotificationType {
	ExpireSchedule = 'expire-schedule',
	Suggestion = 'suggestion',
	SuggestionAccepted = 'suggestion-accepted',
	SuggestionRejected = 'suggestion-rejected'
}

export interface INotification {
	id: number;
	title: string;
	message: string;
	type: NotificationType;
	data: INotificationData;
	read: boolean;
	createdAt: Date;
	updatedAt: Date;
	deletedAt: null;
}

export type NotificationQueryParameters = {
	page?: number;
	limit?: number;
};
