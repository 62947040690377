export type Inventory = {
	id: number;
	title: string;
	movieTMDBId: number | null;
	poster_path: string;
	posterCount: number;
	posterSmallCount: number;
	totalPosters: number;
	createdAt: Date;
	updatedAt: Date;
	deletedAt?: Date;
};

export type MovieInventoryDto = Omit<Inventory, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'>;
export type MovieInventoryUpdateDto = Omit<Inventory, 'createdAt' | 'updatedAt' | 'deletedAt'>;

export enum PosterFilter {
	ALL = 'ALL',
	EMPTY = 'EMPTY',
	NOT_EMPTY = 'NOT_EMPTY'
}

export type QueryParametersInventory = {
	page?: number;
	pageSize?: number;
	search?: string;
	posterFilter?: PosterFilter;
	withPostersCount?: boolean;
};
