import { MoviePoster } from '@components/index';
import classNames from 'classnames';
import { truncate } from 'lodash';

interface IMovieItemProps {
	posterPath: string;
	originalTitle: string;
	isSelected?: boolean;
	height?: number;
	width?: number | string;
}

const MovieItem = ({
	posterPath,
	originalTitle,
	isSelected = false,
	height = 200,
	width = '100%'
}: IMovieItemProps) => (
	<div
		className={classNames('relative w-full rounded-lg bg-white shadow', {
			'border-4 border-blue-500': isSelected
		})}>
		<div>
			<MoviePoster posterPath={posterPath} height={height} width={width} />
		</div>
		<div className="flex items-center justify-center p-2" style={{ height: 57 }}>
			<div className="text-center text-xs font-bold">
				{originalTitle &&
					truncate(originalTitle, {
						length: 35,
						separator: ' '
					})}
			</div>
		</div>
	</div>
);

export default MovieItem;
