import { suggestionService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQuerySuggestion(suggestionId: number) {
	return useQuery({
		queryKey: ['Suggestion', suggestionId],
		queryFn: () => suggestionService.getSuggestion(suggestionId),
		enabled: !!suggestionId,
		refetchOnWindowFocus: false
	});
}
