import { useState } from 'react';

import { Empty, PlanningMonthPicker, Skeleton, Tabs } from '@components/index';
import { Container, Header, ScheduleItem, Subheader } from '@elements/index';
import { DATE_DAYJS_FORMAT } from '@enums/dates';
import { useQuerySchedules } from '@hooks/index';
import { ISchedule, PlanningType } from '@interfaces/scheduleInterface';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useNavigate } from 'react-router-dom';

import { QueryParametersSchedules } from './types';

const Planning = () => {
	const fortnightParams: QueryParametersSchedules = {
		startDate: dayjs().format(DATE_DAYJS_FORMAT.US),
		endDate: dayjs().add(15, 'days').format(DATE_DAYJS_FORMAT.US),
		page: 1,
		limit: 5
	};

	const thisMonthParams: QueryParametersSchedules = {
		startDate: dayjs().format(DATE_DAYJS_FORMAT.US),
		endDate: dayjs().endOf('month').format(DATE_DAYJS_FORMAT.US),
		page: 1,
		limit: 5
	};

	const navigate = useNavigate();

	const [scheduleOpen, setScheduleOpen] = useState<number>();
	const [activeMonthSlider, setActiveMonthSlider] = useState(false);
	const [datesParams, setDatesParams] = useState<QueryParametersSchedules>(fortnightParams);
	const [filterSelected, setFilterSelected] = useState(PlanningType.Fortnight);

	const {
		data: schedules,
		isLoading: isLoadingSchedules,
		hasNextPage: schedulesHasNextPage,
		fetchNextPage: schedulesFetchNextPage
	} = useQuerySchedules(datesParams);

	const [sentryRef] = useInfiniteScroll({
		loading: isLoadingSchedules,
		hasNextPage: schedulesHasNextPage || false,
		onLoadMore: schedulesFetchNextPage,
		rootMargin: '0px 0px 400px 0px'
	});

	const onChangeDateMonthPicker = (startDate: string) => {
		setDatesParams({
			startDate: dayjs(startDate).startOf('month').format(DATE_DAYJS_FORMAT.US),
			endDate: dayjs(startDate).endOf('month').format(DATE_DAYJS_FORMAT.US),
			page: 1,
			limit: 5
		});
	};

	const onChangeTypePlanning = (type: PlanningType) => {
		setFilterSelected(type);
		if (type === PlanningType.Fortnight) {
			setDatesParams(fortnightParams);
		} else {
			setDatesParams(thisMonthParams);
		}
		setActiveMonthSlider(!activeMonthSlider);
	};

	const schedulesByDate = (schedulesToGrouped: ISchedule[]) =>
		groupBy(schedulesToGrouped, (schedule) => dayjs(schedule.date).format(DATE_DAYJS_FORMAT.US));

	return (
		<>
			<Header />
			<Container>
				<Subheader
					title={`Planning par ${activeMonthSlider ? 'mois' : 'quinzaine'}`}
					controls={
						<div className="flex justify-center md:justify-start">
							<Tabs className="flex">
								<Tabs.Item
									isActive={filterSelected === PlanningType.Fortnight}
									onClick={() => {
										navigate('/planning');
										onChangeTypePlanning(PlanningType.Fortnight);
									}}>
									Voir par quinzaine
								</Tabs.Item>
								<Tabs.Item
									isActive={filterSelected === PlanningType.Month}
									onClick={() => {
										navigate('/planning');
										onChangeTypePlanning(PlanningType.Month);
									}}>
									Voir par mois
								</Tabs.Item>
							</Tabs>
						</div>
					}
				/>

				{activeMonthSlider && (
					<div className="my-6 flex justify-center">
						<PlanningMonthPicker
							date={datesParams.startDate as string}
							onChangeDate={(startDate) => onChangeDateMonthPicker(startDate)}
						/>
					</div>
				)}

				{isLoadingSchedules && (
					<div className="flex flex-col gap-2">
						<div className="flex flex-col gap-2">
							<Skeleton height={28} width={300} />
							<div className="flex flex-col gap-2">
								<Skeleton height={150} width="100%" />
							</div>
						</div>
						<div className="flex flex-col gap-2">
							<Skeleton height={28} width={300} />
							<div className="flex flex-col gap-2">
								<Skeleton height={150} width="100%" />
							</div>
						</div>
						<div className="flex flex-col gap-2">
							<Skeleton height={28} width={300} />
							<div className="flex flex-col gap-2">
								<Skeleton height={150} width="100%" />
							</div>
						</div>
						<div className="flex flex-col gap-2">
							<Skeleton height={28} width={300} />
							<div className="flex flex-col gap-2">
								<Skeleton height={150} width="100%" />
							</div>
						</div>
					</div>
				)}

				{!isLoadingSchedules &&
					schedules &&
					Object.entries(schedulesByDate(schedules.pages.flatMap((page) => page.items))).map(
						([key, schedulesItems]) => (
							<div className="flex flex-col gap-2" key={key}>
								<h3 className="hidden text-xl font-bold text-gray-800 md:flex">
									{dayjs(key).format('dddd, D MMMM YYYY')}
								</h3>
								{(schedulesItems as ISchedule[]).map((schedule) => (
									<ScheduleItem
										key={schedule.id}
										schedule={schedule}
										scheduleOpen={scheduleOpen as number}
										setScheduleOpen={setScheduleOpen}
									/>
								))}

								{(isLoadingSchedules || schedulesHasNextPage) && <div ref={sentryRef} />}
							</div>
						)
					)}

				{!schedulesHasNextPage && !isLoadingSchedules && (
					<div className="flex flex-col items-center py-2">
						<hr className="mx-auto my-3 h-0.5 w-48 rounded border-0 bg-slate-400 " />
					</div>
				)}

				{schedules && schedules.pages.length === 0 && (
					<div className="flex items-center justify-center">
						<Empty title="Aucun résultat" description="Il n'y a pas de séance pour le moment" />
					</div>
				)}
			</Container>
		</>
	);
};

export default Planning;
