import { useState } from 'react';

import { Empty } from '@components/index';
import { Container, Header, NotificationItem, Subheader } from '@elements/index';
import { useQueryNotifications, useQueryNotificationsReadAll } from '@hooks/index';
import { Button, Pagination } from 'antd';

const Notifications = () => {
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(20);
	const { data: notifications } = useQueryNotifications({ page, limit: pageSize });

	const { refetch: refetchNotificationsReadAll } = useQueryNotificationsReadAll();

	const onHandlerPageSizeChange = (current: number, size: number) => {
		setPageSize(size);
	};

	return (
		<>
			<Header />
			<Container>
				<Subheader
					title={
						notifications && notifications.meta.totalItems > 0
							? `Mes notifications (${notifications.meta.totalItems})`
							: 'Mes notifications'
					}
					controls={
						<Button type="primary" htmlType="button" onClick={() => refetchNotificationsReadAll()}>
							Marquer comme lues
						</Button>
					}
				/>

				<div className="flex flex-col gap-2">
					{notifications &&
						notifications.items.map((notification) => (
							<div key={notification.id}>
								<NotificationItem
									className="rounded-xl bg-white"
									notificationId={notification.id}
									title={notification.title}
									isRead={notification.read}
									type={notification.type}
									data={notification.data}
								/>
							</div>
						))}

					{notifications && notifications.meta.totalItems === 0 && (
						<div className="my-6 flex flex-col items-center justify-center gap-4">
							<Empty title="Aucun résultat" description="Pas de notification pour le moment." />
						</div>
					)}

					{notifications && notifications.items && notifications.meta.totalItems > pageSize && (
						<div className="my-6 flex justify-end">
							<Pagination
								showSizeChanger
								onShowSizeChange={(current, size) => onHandlerPageSizeChange(current, size)}
								current={page}
								total={notifications.meta.totalItems}
								pageSize={pageSize}
								onChange={(pageChanged, pageSizeChanged) => {
									setPage(pageChanged);
									setPageSize(pageSizeChanged);
								}}
							/>
						</div>
					)}
				</div>
			</Container>
		</>
	);
};

export default Notifications;
