import { useEffect } from 'react';

import { ScheduleUser, ScheduleCast } from '@components/index';
import { DATE_DAYJS_FORMAT } from '@enums/dates';
import { ISchedule } from '@interfaces/scheduleInterface';
import { Role } from '@interfaces/userInterface';
import { Tag } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

interface IScheduleItemProps {
	schedule: ISchedule;
	setScheduleOpen: (scheduleId: number) => void;
	scheduleOpen: number;
}

const ScheduleItem = ({ schedule, setScheduleOpen, scheduleOpen }: IScheduleItemProps) => {
	const { scheduleId: scheduleIdFocus } = useParams();

	const showInformationHandler = (scheduleId: number | undefined) => {
		setScheduleOpen(scheduleId as number);
	};

	useEffect(() => {
		if (scheduleIdFocus) {
			const ref = document.getElementById(`schedule-${scheduleIdFocus}`);
			window.scrollTo({
				top: Number(ref?.offsetTop) - 42,
				behavior: 'smooth'
			});
		}
	}, [scheduleIdFocus]);

	return (
		<div
			className={classNames('mb-3 rounded-xl bg-white px-4 py-4 shadow', {
				'border-2 border-blue-400': Number(scheduleIdFocus) === schedule.id
			})}
			id={`schedule-${schedule.id}`}>
			<div className="flex flex-col gap-2 md:flex-row">
				<div className="flex flex-col items-center md:w-col-30 md:items-start">
					<div className="text-center text-lg font-bold md:text-left">{schedule.movie.title}</div>
					<div className="font-semibold">{dayjs(schedule.date).format(DATE_DAYJS_FORMAT.FR_LONG)} </div>
					<div>{dayjs(schedule.date).format('HH:mm')}</div>

					<div className="flex flex-wrap gap-2">
						{schedule.type.map((type) => (
							<div className="" key={type}>
								<Tag className="m-0 !me-0 !ms-0 p-0" color="#94A3B8">
									{type}
								</Tag>
							</div>
						))}
					</div>
				</div>
				<div className="flex w-full flex-col gap-4 text-center md:flex-row">
					<div className="w-full md:w-col-20">
						<div className="font-bold">Responsable</div>
						<ScheduleUser schedule={schedule} user={schedule.responsable} role={Role.Responsable} />
					</div>
					<div className="w-full md:w-col-20">
						<div className="font-bold">Caissier</div>
						<ScheduleUser schedule={schedule} user={schedule.cashier} role={Role.Cashier} />
					</div>
					<div className="w-full md:w-col-20">
						<div className="font-bold">Opérateur</div>
						<ScheduleUser schedule={schedule} user={schedule.operator} role={Role.Operator} />
					</div>
					<div className="w-full md:w-col-20">
						<div className="font-bold">Contrôleur</div>
						<ScheduleUser schedule={schedule} user={schedule.controller} role={Role.Controller} />
					</div>
					<div className="w-full md:w-col-20">
						<div className="font-bold">Confiserie</div>
						<ScheduleUser schedule={schedule} user={schedule.store} role={Role.Store} />
					</div>
				</div>
			</div>

			<ScheduleCast
				schedule={schedule}
				open={schedule.id === scheduleOpen}
				setScheduleOpen={(scheduleId) => showInformationHandler(scheduleId)}
			/>
		</div>
	);
};

export default ScheduleItem;
