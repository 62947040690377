import { useEffect } from 'react';

import { useMutationLogin } from '@hooks/index';
import { Status } from '@interfaces/apiInterface';
import { IAuthDto } from '@interfaces/authInterface';
import { authService } from '@services/index';
import { Button, Input, message } from 'antd';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { string, object } from 'yup';

const Login = () => {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const expired = searchParams.get('expired');

	const tokenLocal = authService.getLocalToken();

	const { data: dataLogin, mutate: mutationLogin, status: statusLogin, isPending: isLoadinLogin } = useMutationLogin();

	const onSubmitHandler = async (values: IAuthDto) => {
		mutationLogin(values);
	};

	// Form
	const { touched, errors, getFieldProps, handleSubmit, isValid } = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		onSubmit: onSubmitHandler,
		validationSchema: object({
			email: string().required('Adresse mail requise').email('Adresse mail doit être une adresse mail valide.'),
			password: string().required('Mot de passe requis')
		})
	});

	useEffect(() => {
		if (dataLogin && dataLogin.passwordChangeed === 1 && tokenLocal) {
			navigate('/planning');
		}

		if (dataLogin && dataLogin.passwordChangeed === 0 && tokenLocal) {
			navigate('/users/change-password');
		}
	}, [dataLogin, tokenLocal]);

	useEffect(() => {
		if (tokenLocal) {
			navigate('/planning');
		}
	}, [tokenLocal]);

	useEffect(() => {
		if (statusLogin === Status.Error) {
			message.warning('Vous avez renseigné un nom d’utilisateur ou un mot de passe non valide.');
		}
	}, [statusLogin]);

	useEffect(() => {
		if (expired) {
			message.error('Votre session a expiré, veuillez vous connecter à nouveau.');
			searchParams.delete('expired');
			setSearchParams(searchParams);
		}
	}, [expired]);

	return (
		<div className="container mx-auto">
			<div className="flex min-h-screen flex-col items-center justify-center">
				{!tokenLocal && (
					<>
						<h1 className="mb-5 text-center text-2xl font-bold">Cinéma Le Cep - Vallet</h1>
						<div className="w-col-80 divide-y divide-gray-200 rounded-lg bg-white shadow md:w-col-50 lg:w-col-40 xl:w-col-30">
							<form noValidate autoComplete="off" onSubmit={handleSubmit}>
								<div className="px-5 py-7">
									<div className="mb-5">
										<label htmlFor="email" className="block pb-1 text-sm font-semibold text-gray-600">
											Adresse mail
										</label>
										<Input
											status={touched.email && errors.email ? 'error' : ''}
											id="email"
											{...getFieldProps('email')}
										/>
										{touched.email && errors.email && (
											<small className="text-xs text-red-500">{errors.email}</small>
										)}
									</div>
									<div className="mb-5">
										<label htmlFor="password" className="block pb-1 text-sm font-semibold text-gray-600">
											Mot de passe
										</label>
										<Input
											status={touched.password && errors.password ? 'error' : ''}
											type="password"
											id="password"
											{...getFieldProps('password')}
										/>
										{touched.password && errors.password && (
											<small className="text-xs text-red-500">{errors.password}</small>
										)}
									</div>
									<Button loading={isLoadinLogin} disabled={!isValid} block type="primary" htmlType="submit">
										Se connecter
									</Button>
								</div>
							</form>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Login;
