import { notificationService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryNotificationRead(notificationId: number) {
	return useQuery({
		queryKey: ['NotificationRead', notificationId],
		queryFn: () => notificationService.readNotification(notificationId),
		enabled: false
	});
}
