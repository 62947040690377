import classNames from 'classnames';
import zxcvbn from 'zxcvbn';

type PasswordStrengthMeterProps = {
	password: string;
};

const PasswordStrengthMeter = ({ password }: PasswordStrengthMeterProps) => (
	<div className="flex gap-2">
		{[0, 1, 2, 3, 4].map((item) => (
			<div
				key={item}
				className={classNames('h-1 w-full bg-slate-200', {
					'!bg-red-500': zxcvbn(password).score === 0 && zxcvbn(password).score > item - 1,
					'!bg-orange-500': zxcvbn(password).score === 1 && zxcvbn(password).score > item - 1,
					'!bg-yellow-500': zxcvbn(password).score === 2 && zxcvbn(password).score > item - 1,
					'!bg-green-500': zxcvbn(password).score === 3 && zxcvbn(password).score > item - 1,
					'!bg-green-600': zxcvbn(password).score === 4 && zxcvbn(password).score > item - 1
				})}
			/>
		))}
	</div>
);

export default PasswordStrengthMeter;
