import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div className="flex h-screen flex-col items-center justify-center gap-6 text-center">
			<div className="flex w-full  flex-col gap-1 md:w-col-40">
				<h1 className="text-2xl font-bold">Page non trouvée</h1>
				<div className="text-slate-600">
					Malheureusement, nous n&apos;avons pas pu trouver la page que vous souhaitez consulter.
				</div>
			</div>
			<Button type="primary" onClick={() => navigate('/')}>
				Retour à la page d&apos;accueil
			</Button>
		</div>
	);
};

export default NotFound;
