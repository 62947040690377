import { useEffect, useState } from 'react';

import CardUser from '@components/CardUser/CardUser';
import { Empty, FiltersUser, Search, Skeleton } from '@components/index';
import { Container, Header, ModalUserAdd, ModalUserEdit, Subheader } from '@elements/index';
import { useMutationDeleteUser, useQueryUsers } from '@hooks/index';
import IconPlusCircle from '@icons/IconPlusCircle';
import { Button, Pagination, message } from 'antd';

const Users = () => {
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(20);

	const [search, setSearch] = useState('');
	const [role, setRole] = useState('');

	const [showUserAddModal, setShowUserAddModal] = useState(false);

	const [userToUpdate, setUserToUpdate] = useState<number>();
	const [showUserEditModal, setShowUserEditModal] = useState(false);

	const {
		data: users,
		refetch: refetchUsers,
		isLoading: isLoadingUsers
	} = useQueryUsers({
		page,
		perPage: pageSize,
		fullName: search,
		role
	});
	const { mutate: mutationUser, isSuccess: isSuccessMutationUser } = useMutationDeleteUser();

	const onSearch = (valueToSearch: string) => {
		setSearch(valueToSearch);
		setPage(1);
	};

	const onDelete = (userId: number) => {
		mutationUser({
			userId
		});
	};

	const onHandlerEditUser = (userId: number) => {
		setUserToUpdate(userId);
		setShowUserEditModal(true);
	};

	const onHandlerSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setSearch((event.target as HTMLInputElement).value);
			setPage(1);
		}
	};

	useEffect(() => {
		if (isSuccessMutationUser) {
			message.success('Bénévole correctement éliminé.');
			refetchUsers();
		}
	}, [isSuccessMutationUser]);

	useEffect(() => {
		refetchUsers();
	}, [search, page, pageSize, role]);

	return (
		<div>
			<Header />
			<Container>
				<Subheader
					title="Liste des bénévoles"
					controls={
						<Button type="primary" htmlType="button" onClick={() => setShowUserAddModal(true)}>
							<IconPlusCircle height={16} width={16} className="mr-1" /> Ajouter un bénévole
						</Button>
					}
				/>

				<div className="my-4">
					<Search
						placeholder="Rechercher un bénévole"
						onSearch={(value) => onSearch(value)}
						onKeyDown={(event) => onHandlerSearchKeyDown(event)}
						onClear={() => setSearch('')}
						value={search}
					/>
				</div>

				<div className="my-4 block md:block lg:flex">
					<FiltersUser onChange={setRole} />
				</div>

				{users && users.items.length > 0 && (
					<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
						{users.items.map((user) => (
							<CardUser
								onUpdate={onHandlerEditUser}
								user={user}
								key={user.id}
								onDelete={(userId) => onDelete(userId)}
							/>
						))}
					</div>
				)}

				{isLoadingUsers && (
					<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
						<Skeleton height={178} width="100%" />
						<Skeleton height={178} width="100%" />
						<Skeleton height={178} width="100%" />
						<Skeleton height={178} width="100%" />
					</div>
				)}

				{users && users.meta.totalItems === 0 && (
					<div className="my-6 flex flex-col items-center justify-center gap-4">
						<Empty title="Aucun résultat" description="Aucun bénévole ayant ce nom n'a été trouvé" />
						<Button type="primary" onClick={() => setSearch('')}>
							Réinitialiser la recherche
						</Button>
					</div>
				)}

				{users && users.items && users.meta.totalItems > pageSize && (
					<div className="my-6 flex justify-end">
						<Pagination
							current={page}
							total={users.meta.totalItems}
							pageSize={pageSize}
							onChange={(pageChanged, pageSizeChanged) => {
								setPage(pageChanged);
								setPageSize(pageSizeChanged);
							}}
						/>
					</div>
				)}

				{showUserAddModal && <ModalUserAdd open={showUserAddModal} onOpen={setShowUserAddModal} />}
				{userToUpdate && showUserEditModal && (
					<ModalUserEdit userToUpdate={userToUpdate} open={showUserEditModal} onOpen={setShowUserEditModal} />
				)}
			</Container>
		</div>
	);
};

export default Users;
