import { useEffect, useState } from 'react';

import { MovieItem } from '@components/index';
import { DATE_DAYJS_FORMAT } from '@enums/dates';
import { useQueryMovieTMDB, useQuerySuggestion } from '@hooks/index';
import useMutationUpdateSuggestion from '@hooks/suggestion/useMutationUpdateSuggestion';
import { Status } from '@interfaces/apiInterface';
import { SuggestionDecision, SuggestionsStatus } from '@interfaces/suggestionInterface';
import { durationMovie } from '@utils/durationMovie';
import { Button, Spin, message } from 'antd';
import dayjs from 'dayjs';
import { truncate } from 'lodash';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const Suggestion = () => {
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const suggestionId = searchParams.get('suggestionId');

	const [decision, setDecision] = useState<SuggestionDecision>();

	const { data: suggestion, isLoading } = useQuerySuggestion(Number(suggestionId));
	const movie = suggestion && suggestion.schedule.movie;

	const { data: movieTMDB } = useQueryMovieTMDB({
		movieTMDBId: movie?.movieDBId as number,
		config: { enabled: !!movie?.movieDBId && movie.movieDBId !== null }
	});

	const { mutate: mutateUpdateSuggestion, status: statusUpdateSuggestion } = useMutationUpdateSuggestion();

	const suggestionAcceptHandler = (suggestionDecision: SuggestionDecision) => {
		setDecision(suggestionDecision);

		if (!suggestion) return;

		mutateUpdateSuggestion({
			id: suggestion.id,
			scheduleId: suggestion.schedule.id,
			userSuggester: suggestion.userSuggester.id,
			userToSuggest: suggestion.userToSuggest.id,
			role: suggestion.role,
			decision: suggestionDecision
		});
	};

	useEffect(() => {
		if (statusUpdateSuggestion === Status.Success) {
			if (decision === SuggestionDecision.Accepted) {
				message.success('Le remplacement a été correctement accepté.');
				navigate(`/planning/${suggestion ? suggestion.schedule.id : ''}`);
			}

			if (decision === SuggestionDecision.Rejected) {
				message.success('Le remplacement a été correctement refusé.');
				navigate('/planning');
			}
		}

		if (statusUpdateSuggestion === Status.Error) {
			message.warning('Une erreur est survenue, veuillez réessayer plus tard.');
		}
	}, [statusUpdateSuggestion]);

	return (
		<div className="container mx-auto my-6 flex flex-col items-center justify-center md:h-screen">
			{!isLoading && suggestion && suggestion.status === SuggestionsStatus.Taken && (
				<div className="rounded bg-white p-8 text-center">
					<div className="flex flex-col gap-6">
						<div className="font-bold">Ce remplacement a été pourvu pour un bénévole.</div>
						<div>
							<Link to="/planning">
								<Button htmlType="button" block type="primary" size="large">
									Revenir au planning
								</Button>
							</Link>
						</div>
					</div>
				</div>
			)}

			{!isLoading && suggestion && suggestion.status === SuggestionsStatus.Pending && (
				<div className="w-col-90 rounded-lg bg-white p-8 text-center md:w-col-50">
					<div className="flex flex-col gap-4">
						<div className="text-xl font-bold">Quelqu’un vous a proposé pour le remplacer lors d'une séance</div>
						<div className="">
							{suggestion.userSuggester.fullName} souhaiterait savoir si vous pourriez le/la remplacer pour:
						</div>

						{movie && (
							<div className="flex flex-col gap-4 rounded-lg border p-6 md:flex-row">
								<div className="w-col-80 self-center md:w-col-30">
									<MovieItem
										originalTitle={
											suggestion.schedule.movie.original_title
												? suggestion.schedule.movie.original_title
												: suggestion.schedule.movie.title
										}
										posterPath={suggestion.schedule.movie.poster_path}
									/>
								</div>
								<div className="md:w-col-70">
									<div className="text-center md:text-left">
										<div className="text-lg font-bold">{movie.title}</div>
										<div>
											<span className="font-bold">Durée:</span>{' '}
											{movieTMDB && durationMovie(movieTMDB.runtime)}
										</div>
										<p className="py-2">
											{truncate(movieTMDB && movieTMDB.overview, {
												length: 200
											})}
										</p>
									</div>
								</div>
							</div>
						)}

						<div className="flex flex-col gap-4 md:flex-row">
							<div className="rounded bg-gray-100 p-4 md:w-col-50">
								<div className="font-bold">Film:</div>
								<div className="text-sm">{suggestion.schedule.movie.title}</div>
							</div>
							<div className="rounded bg-gray-100 p-4 md:w-col-50">
								<div className="font-bold">Date:</div>
								<div className="text-sm">
									{dayjs(suggestion.schedule.date).format(DATE_DAYJS_FORMAT.FR_LONG_WITH_HOUR)}
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-4 md:flex-row">
							<Button
								className="w-full md:w-col-50"
								type="primary"
								ghost
								danger
								size="large"
								onClick={() => suggestionAcceptHandler(SuggestionDecision.Rejected)}>
								Refuser
							</Button>
							<Button
								className="w-full md:w-col-50"
								type="primary"
								size="large"
								onClick={() => suggestionAcceptHandler(SuggestionDecision.Accepted)}>
								Accepter
							</Button>
						</div>
					</div>
				</div>
			)}

			{isLoading && (
				<div className="flex h-60 items-center justify-center">
					<Spin />
				</div>
			)}
		</div>
	);
};
export default Suggestion;
