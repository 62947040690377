import { QueryParametersMovies } from '@interfaces/movieInterface';
import { movieService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryMovies({ page, pageSize, search }: QueryParametersMovies) {
	return useQuery({
		queryKey: ['Movies', page, pageSize, search],
		queryFn: () => movieService.getMovies(page, pageSize, search),
		enabled: !!page || !!pageSize || !!search,
		refetchOnWindowFocus: false
	});
}
