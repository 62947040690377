import { VacationDto } from '@interfaces/vacationInterface';
import { vacationService } from '@services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationVacation() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (vacationDto: VacationDto) => vacationService.createVacation(vacationDto),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['MyVacations'] });
		}
	});
}
