import { IAuthDto } from '@interfaces/authInterface';
import { authService } from '@services/index';
import { useMutation } from '@tanstack/react-query';

export default function useMutationLogin() {
	return useMutation({
		mutationFn: ({ email, password }: IAuthDto) => authService.login({ email, password }),
		retry: 0
	});
}
