import { movieService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryMovie(movieId: number) {
	return useQuery({
		queryKey: ['Movies', movieId],
		queryFn: () => movieService.getMovie(movieId),
		enabled: !!movieId
	});
}
