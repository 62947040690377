import { IMovie } from './movieInterface';
import { IUser } from './userInterface';

export enum PlanningType {
	Fortnight = 'fortnight',
	Month = 'month'
}

export interface ISchedule {
	id: number;
	date: string;
	type: string[];
	createdAt: string;
	updatedAt: string;
	deletedAt: null;
	responsable: IUser;
	cashier: IUser;
	controller: IUser;
	store: IUser;
	operator: IUser;
	glasses: IUser;
	movie: IMovie;
}

export type IScheduleDto = {
	id: number;
	date: string;
	type: string[];
	createdAt: string;
	updatedAt: string;
	deletedAt: null;
	responsable: number | null;
	cashier: number | null;
	controller: number | null;
	store: number | null;
	operator: number | null;
	glasses: number | null;
	movie: IMovie;
};

export type ScheduleGrouped = {
	[key: string]: ISchedule[];
};

export type OrderByKeys =
	| 'id'
	| 'firstName'
	| 'lastName'
	| 'fullName'
	| 'responsable'
	| 'cashier'
	| 'controller'
	| 'store'
	| 'operator'
	| 'glasses'
	| 'total';

export type QueryParametersParticipationsUser = {
	startDate?: string;
	endDate?: string;
	orderBy?: Partial<Record<OrderByKeys, 'ASC' | 'DESC'>>;
};
