import { QueryParametersInventory } from '@interfaces/inventoryInterface';
import { inventoryService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryInventories({
	page,
	pageSize,
	search,
	posterFilter,
	withPostersCount
}: QueryParametersInventory) {
	return useQuery({
		queryKey: ['Inventory', page, pageSize, search, posterFilter, withPostersCount],
		queryFn: () => inventoryService.getInventories(page, pageSize, search, posterFilter, withPostersCount),
		enabled: !!page || !!pageSize || !!search,
		refetchOnWindowFocus: false
	});
}
