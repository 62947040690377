import { HttpMessage } from '@interfaces/apiInterface';
import { Vacation, VacationDto } from '@interfaces/vacationInterface';

import axiosInstance from './interceptor';

export const getVacations = async (): Promise<Vacation[]> => {
	const vacations = await axiosInstance.get<Vacation[]>(
		`${import.meta.env.VITE_API_URL}/vacation/`,
		{}
	);
	return vacations.data;
};

export const getUserVacations = async (): Promise<Vacation[]> => {
	const vacations = await axiosInstance.get<Vacation[]>(
		`${import.meta.env.VITE_API_URL}/vacation/my-vacations/`,
		{}
	);
	return vacations.data;
};

export const createVacation = async (
	vacationDto: VacationDto
): Promise<Vacation> => {
	const vacation = await axiosInstance.post<Vacation>(
		`${import.meta.env.VITE_API_URL}/vacation/`,
		vacationDto
	);
	return vacation.data;
};

export const deleteVacation = async (
	vacationId: number
): Promise<HttpMessage> => {
	const vacation = await axiosInstance.delete<HttpMessage>(
		`${import.meta.env.VITE_API_URL}/vacation/${vacationId}`
	);
	return vacation.data;
};
