import { useEffect, useState } from 'react';

import { Container, Header, MovieTMDBSearch, Subheader } from '@elements/index';
import { MovieTMDBSearchType, MovieType } from '@elements/Movie/types';
import { useMutationCreateInventory, useQueryInventoryGetBy } from '@hooks/index';
import { Status } from '@interfaces/apiInterface';
import { MovieInventoryDto } from '@interfaces/inventoryInterface';
import { IMovieTMDB } from '@interfaces/movieInterface';
import { Button, Input, message } from 'antd';
import { useFormik } from 'formik';
import { Minus, Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const InventoryAdd = () => {
	const navigate = useNavigate();

	const [title, setTitle] = useState('');
	const [year, setYear] = useState('');

	const [selectMovieTMDB, setSelectMovieTMDB] = useState<IMovieTMDB>();

	const { data: movieInDatabase } = useQueryInventoryGetBy(selectMovieTMDB?.id as number);
	const {
		mutate: mutateCreateInventory,
		status: statusCreateInventory,
		isPending: isLoadingCreateInventory
	} = useMutationCreateInventory();

	const onSubmitHandler = async (values: MovieInventoryDto) => {
		mutateCreateInventory(values);
	};

	// Form
	const { values, setValues, resetForm, handleSubmit, getFieldProps, touched, errors, isValid, dirty } =
		useFormik<MovieInventoryDto>({
			initialValues: {
				title: '',
				poster_path: '',
				movieTMDBId: 0,
				posterCount: 0,
				posterSmallCount: 0,
				totalPosters: 0
			},
			onSubmit: onSubmitHandler,
			validationSchema: Yup.object({
				title: Yup.string().required('El titulo es requerido')
			})
		});

	const handlerMovieSelected = (movieTMDBSelected: IMovieTMDB): void => {
		setValues((prevValues) => ({
			...prevValues,
			title: title as string,
			poster_path: movieTMDBSelected.poster_path,
			movieTMDBId: movieTMDBSelected.id ? Number(movieTMDBSelected.id) : null
		}));
	};

	const onClearForm = () => {
		resetForm();
		setSelectMovieTMDB(undefined);
		navigate('/inventory');
	};

	useEffect(() => {
		if (statusCreateInventory === Status.Success) {
			message.success('Film correctement créé');
			navigate('/inventory');
		}

		if (statusCreateInventory === Status.Error) {
			message.warning('Une erreur est survenue, veuillez réessayer plus tard.');
		}
	}, [statusCreateInventory]);

	useEffect(() => {
		if (!title) return;

		setValues((prevValues) => ({
			...prevValues,
			title: title as string
		}));
	}, [title]);

	return (
		<>
			<Header />
			<Container>
				<Subheader title="Ajouter un film" />

				<div className="flex gap-2 md:grid md:grid-cols-5">
					<div className="col-span-2">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Information du film</h3>
						<MovieTMDBSearch
							movieInDatabase={movieInDatabase}
							mode={MovieTMDBSearchType.Add}
							type={MovieType.Inventory}
							resultsToShow={6}
							title={title as string}
							year={year as string}
							setTitle={setTitle}
							setYear={setYear}
							selectMovieTMDB={selectMovieTMDB as IMovieTMDB}
							setSelectMovieTMDB={setSelectMovieTMDB}
							handlerMovieSelected={handlerMovieSelected}
							buttonText="Chercher le film"
						/>

						<hr />
					</div>

					<div className="col-span-3">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Inventaire</h3>
						<form className="" noValidate autoComplete="off" onSubmit={handleSubmit}>
							<div className="flex justify-center gap-4 rounded-lg bg-white p-4">
								<div>
									<label className="font-bold" htmlFor="posterCount">
										Affiches
									</label>
									<div className="flex gap-1">
										<div>
											<Button
												size="large"
												type="primary"
												icon={<Minus size="16px" />}
												onClick={() => {
													if (values.posterCount > 0) {
														setValues((prevValues) => ({
															...prevValues,
															posterCount: values.posterCount - 1
														}));
													}
												}}
											/>
										</div>
										<Input
											size="large"
											type="number"
											className="!w-24 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
											status={touched.posterCount && errors.posterCount ? 'error' : undefined}
											id="posterCount"
											{...getFieldProps('posterCount')}
										/>
										<div>
											<Button
												size="large"
												type="primary"
												icon={<Plus size="16px" />}
												onClick={() => {
													setValues((prevValues) => ({
														...prevValues,
														posterCount: values.posterCount + 1
													}));
												}}
											/>
										</div>
									</div>
								</div>
								<div>
									<label className="font-bold" htmlFor="posterSmallCount">
										Affichettes
									</label>
									<div className="flex gap-1">
										<div>
											<Button
												size="large"
												type="primary"
												icon={<Minus size="16px" />}
												onClick={() => {
													if (values.posterSmallCount > 0) {
														setValues((prevValues) => ({
															...prevValues,
															posterSmallCount: values.posterSmallCount - 1
														}));
													}
												}}
											/>
										</div>
										<Input
											size="large"
											type="number"
											className="!w-24 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
											status={touched.posterSmallCount && errors.posterSmallCount ? 'error' : undefined}
											id="posterSmallCount"
											{...getFieldProps('posterSmallCount')}
										/>
										<div>
											<Button
												size="large"
												type="primary"
												icon={<Plus size="16px" />}
												onClick={() => {
													setValues((prevValues) => ({
														...prevValues,
														posterSmallCount: values.posterSmallCount + 1
													}));
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className=" my-2 flex justify-end gap-2">
								<Button type="default" htmlType="button" onClick={() => onClearForm()}>
									Annuler
								</Button>
								<Button
									type="primary"
									loading={isLoadingCreateInventory}
									htmlType="submit"
									disabled={!(isValid && dirty)}>
									Enregistrer
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Container>
		</>
	);
};

export default InventoryAdd;
