import { inventoryService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryInventoryGetBy(movieTMDBId: number) {
	return useQuery({
		queryKey: ['Inventory', movieTMDBId],
		queryFn: () => inventoryService.getInventoryByMovieTMDBId(movieTMDBId),
		enabled: !!movieTMDBId,
		refetchOnWindowFocus: false
	});
}
