import { IconEmpty } from '@icons/index';

interface IEmptyProps {
	title: string;
	description: string;
	height?: number;
	icon?: React.ReactNode;
}

const Empty = ({ title, description, height = 250, icon }: IEmptyProps) => (
	<div style={{ height }} className="flex flex-col items-center justify-center text-center">
		{icon && icon}
		{!icon && <IconEmpty width={60} height={60} />}
		<div className="my-2 text-xl font-bold">{title}</div>
		<div className="text-slate-500">{description}</div>
	</div>
);

export default Empty;
