import { useEffect } from 'react';

import { useMutationAddUser } from '@hooks/index';
import { Status } from '@interfaces/apiInterface';
import { UserDto } from '@interfaces/userInterface';
import { Checkbox, Input, Modal, message } from 'antd';
import { useFormik } from 'formik';
import { array, number, object, string } from 'yup';

type ModalUserAddProps = {
	open: boolean;
	onOpen: (open: boolean) => void;
};

export const rolesList = [
	{
		label: 'Admin',
		value: 1
	},
	{
		label: 'Responsable',
		value: 2
	},
	{
		label: 'Caissier',
		value: 3
	},
	{
		label: 'Contrôleur',
		value: 4
	},
	{
		label: 'Opérateur',
		value: 5
	},
	{
		label: 'Confiserie',
		value: 6
	},
	{
		label: 'Affiches',
		value: 7
	}
];

const ModalUserAdd = ({ open, onOpen }: ModalUserAddProps) => {
	const { mutate: mutateAddUser, status, isPending: isLoadingAddUser } = useMutationAddUser();

	const onSubmitHandler = async (values: UserDto) => {
		mutateAddUser(values);
	};

	// Form
	const { values, setFieldValue, isValid, dirty, resetForm, handleSubmit, getFieldProps, errors, touched } =
		useFormik<UserDto>({
			initialValues: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				movil: '',
				roles: []
			},
			onSubmit: onSubmitHandler,
			validationSchema: object({
				firstName: string().required('Veuillez renseigner un prénom'),
				lastName: string().required('Veuillez renseigner un nom'),
				email: string().required('Adresse mail requise').email('Veuillez renseigner une adresse mail valide.'),
				phone: string().required('Veuillez renseigner un numéro de téléphone'),
				movil: string().required('Veuillez renseigner un numéro de téléphone portable'),
				roles: array().of(number()).min(1, 'Veuillez renseigner au moins un rôle')
			})
		});

	const onCancel = () => {
		resetForm();
		onOpen(false);
	};

	useEffect(() => {
		if (status === Status.Success) {
			message.success('Nouveau bénévole correctement créé.');
			onOpen(false);
		}

		if (status === Status.Error) {
			message.warning('Une erreur est survenue, veuillez réessayer plus tard.');
			onOpen(false);
		}
	}, [status]);

	return (
		<Modal
			centered
			title="Ajouter un bénévole"
			open={open}
			onOk={() => handleSubmit()}
			cancelText="Annuler"
			okText="Enregistrer"
			onCancel={onCancel}
			okButtonProps={{
				disabled: !(isValid && dirty),
				loading: isLoadingAddUser
			}}>
			<form noValidate autoComplete="off">
				<div className="flex flex-col gap-4 py-4">
					<div>
						<label className="font-bold" htmlFor="firstName">
							Prénom
						</label>
						<Input
							status={touched.firstName && errors.firstName ? 'error' : undefined}
							id="firstName"
							{...getFieldProps('firstName')}
						/>
						{touched.firstName && errors.firstName && <small className="text-red-500">{errors.firstName}</small>}
					</div>
					<div>
						<label className="font-bold" htmlFor="lastName">
							Nom
						</label>
						<Input
							status={touched.lastName && errors.lastName ? 'error' : undefined}
							id="lastName"
							{...getFieldProps('lastName')}
						/>
						{touched.lastName && errors.lastName && <small className="text-red-500">{errors.lastName}</small>}
					</div>
					<div>
						<label className="font-bold" htmlFor="email">
							Email
						</label>
						<Input
							status={touched.email && errors.email ? 'error' : undefined}
							id="email"
							{...getFieldProps('email')}
						/>
						{touched.email && errors.email && <small className="text-red-500">{errors.email}</small>}
					</div>
					<div className="flex gap-4">
						<div className="w-6/12">
							<label className="font-bold" htmlFor="phone">
								Téléphone
							</label>
							<Input
								status={touched.phone && errors.phone ? 'error' : undefined}
								id="phone"
								{...getFieldProps('phone')}
							/>
							{touched.phone && errors.phone && <small className="text-red-500">{errors.phone}</small>}
						</div>
						<div className="w-6/12">
							<label className="font-bold" htmlFor="movil">
								Téléphone portable
							</label>
							<Input
								status={touched.movil && errors.movil ? 'error' : undefined}
								id="movil"
								{...getFieldProps('movil')}
							/>
							{touched.movil && errors.movil && <small className="text-red-500">{errors.movil}</small>}
						</div>
					</div>
					<div>
						<label className="font-bold" htmlFor="roles">
							Roles
						</label>
						<Checkbox.Group
							value={values.roles}
							className="!grid grid-cols-2 gap-4 md:grid-cols-3"
							onChange={(roles) => setFieldValue('roles', roles)}>
							{rolesList.map((role) => (
								<Checkbox className="!me-0 !ms-0" key={role.value} value={role.value}>
									{role.label}
								</Checkbox>
							))}
						</Checkbox.Group>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default ModalUserAdd;
