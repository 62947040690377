import { KeyboardEvent, useEffect, useState } from 'react';

import { XCircle } from 'react-feather';

export interface ISearchProps {
	onClear: () => void;
	onSearch: (valueToSearch: string) => void;
	onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
	placeholder?: string;
	value?: string;
}

const Search = ({ onSearch, onClear, onKeyDown, placeholder, value = '' }: ISearchProps) => {
	const [search, setSearch] = useState(value);

	const onSearchHandler = (valueToSearch: string) => {
		setSearch(valueToSearch);
		onSearch(valueToSearch);
	};

	useEffect(() => {
		setSearch(value);
	}, [value]);

	return (
		<div className="rounded-xl bg-white p-2 pr-4 shadow">
			<div className="flex items-center justify-between gap-2">
				<input
					value={search}
					onChange={(event) => onSearchHandler((event.target as HTMLInputElement).value as string)}
					onKeyDown={(event) => onKeyDown(event as KeyboardEvent<HTMLInputElement>)}
					type="text"
					placeholder={placeholder}
					className="w-full flex-grow cursor-text rounded-lg  py-3 pl-5 pr-36 outline-none focus:border-blue-600"
				/>

				{search && search.length > 2 && (
					<div className="text-gray-400">
						<button
							type="button"
							onClick={() => {
								onClear();
								setSearch('');
							}}>
							<XCircle size={20} />
						</button>
					</div>
				)}

				<button
					onClick={() => onSearch(search)}
					type="button"
					className="hidden w-40 cursor-pointer items-center justify-center rounded-md bg-blue-600 py-2 text-center text-white md:flex">
					<div className="flex items-start">Chercher</div>
				</button>
			</div>
		</div>
	);
};

export default Search;
