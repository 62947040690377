import { MovieInventoryUpdateDto } from '@interfaces/inventoryInterface';
import { inventoryService } from '@services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationUpdateInventory() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (inventoryCreateDto: MovieInventoryUpdateDto) =>
			inventoryService.updateInventoryItem(inventoryCreateDto),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['Inventory'] });
		}
	});
}
