import { useEffect, useState } from 'react';

import { useMutationCreateSuggestion, useQueryUsers } from '@hooks/index';
import { Status } from '@interfaces/apiInterface';
import { SuggestionCreateDto } from '@interfaces/suggestionInterface';
import { Role } from '@interfaces/userInterface';
import { Button, Modal, Select, message } from 'antd';
import { useFormik } from 'formik';
import { RefreshCcw } from 'react-feather';
import { number, object, string } from 'yup';

type ScheduleReplaceProps = {
	role: Role;
	scheduleId: number;
	userSuggester: number;
};

const ScheduleReplace = ({ role, scheduleId, userSuggester }: ScheduleReplaceProps) => {
	const [open, setOpen] = useState(false);

	const { data: usersToReplace } = useQueryUsers({ role, page: 1 });
	const {
		mutate: mutateCreateSuggestion,
		status: statusCreateSuggestions,
		isPending: isLoadingCreateSuggestions
	} = useMutationCreateSuggestion();

	const onSubmitHandler = async (values: unknown) => {
		mutateCreateSuggestion(values as SuggestionCreateDto);
	};

	// Form
	const { handleSubmit, values, setFieldValue, isValid } = useFormik({
		initialValues: {
			role: '',
			scheduleId: '',
			userToSuggest: undefined,
			userSuggester: undefined
		},
		onSubmit: onSubmitHandler,
		validationSchema: object({
			role: string().required(),
			scheduleId: number().required(),
			userToSuggest: number().required(),
			userSuggester: number().required()
		})
	});

	const onChangeHandler = (value: number) => {
		setFieldValue('userToSuggest', value);
	};

	const filterOption = (input: string, option?: { value: number; label: string }) => {
		if (!option) {
			return false;
		}
		return option.label.toLowerCase().includes(input.toLowerCase());
	};

	useEffect(() => {
		setFieldValue('role', role);
		setFieldValue('scheduleId', scheduleId);
		setFieldValue('userSuggester', userSuggester);
	}, []);

	useEffect(() => {
		if (statusCreateSuggestions === Status.Error) {
			message.error('Une erreur est survenue, veuillez réessayer plus tard.');
		}
		if (statusCreateSuggestions === Status.Success) {
			message.success('Votre demande de remplacement a été correctement envoyée.');
			setOpen(false);
		}
	}, [statusCreateSuggestions]);

	return (
		<>
			<Button
				className="my-1"
				size="small"
				icon={<RefreshCcw className="mr-2" size={14} />}
				onClick={() => setOpen(true)}>
				Remplacement
			</Button>
			{open && (
				<Modal
					onCancel={() => setOpen(false)}
					footer={
						<div className="flex justify-end">
							<Button type="link" danger htmlType="button" onClick={() => setOpen(false)}>
								Annuler
							</Button>
							<Button
								type="primary"
								htmlType="submit"
								onClick={() => onSubmitHandler(values)}
								disabled={!isValid}
								loading={isLoadingCreateSuggestions}>
								Envoyer la demande de remplacement
							</Button>
						</div>
					}
					width={650}
					open={open}
					title="À quel bénévole souhaitez-vous demander le remplacement ?">
					<form className="flex flex-col gap-2 py-2" noValidate autoComplete="off" onSubmit={handleSubmit}>
						{usersToReplace && (
							<Select
								showSearch
								placeholder="Sélectionnez le bénévole remplaçant"
								onChange={onChangeHandler}
								filterOption={filterOption}
								options={usersToReplace.items
									.filter((user) => user.id !== userSuggester)
									.map((user) => ({
										value: user.id,
										label: user.fullName
									}))}
							/>
						)}
					</form>
				</Modal>
			)}
		</>
	);
};

export default ScheduleReplace;
