import { movieService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryMoviesGetBy(movieDBId: number) {
	return useQuery({
		queryKey: ['Movies', movieDBId],
		queryFn: () => movieService.getMovieBy(movieDBId),
		enabled: !!movieDBId,
		refetchOnWindowFocus: false,
		retry: 1
	});
}
