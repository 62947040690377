import { useState } from 'react';

import { Tabs } from '@components/index';
import { FilterRoleUsers } from '@interfaces/userInterface';

export interface FilterUsersProps {
	onChange: (filter: string) => void;
}

const FiltersUser = ({ onChange }: FilterUsersProps) => {
	const filters: FilterRoleUsers[] = [
		{
			value: '',
			label: 'Tous les rôles'
		},
		{
			value: 'admin',
			label: 'Administrateur'
		},
		{
			value: 'responsable',
			label: 'Responsable'
		},
		{
			value: 'cashier',
			label: 'Caissier'
		},
		{
			value: 'operator',
			label: 'Opérateur'
		},
		{
			value: 'controller',
			label: 'Contrôleur'
		},
		{
			value: 'store',
			label: 'Confiserie'
		},
		{
			value: 'inventory',
			label: 'Affiches'
		}
	];
	const [filterSelected, setFilterSelected] = useState('');

	const onChangeHandler = (filter: string) => {
		setFilterSelected(filter);
		onChange(filter);
	};

	return (
		<Tabs className="grid grid-cols-2 md:grid-cols-4 lg:flex">
			{filters.map((filter) => (
				<Tabs.Item
					isActive={filterSelected === filter.value}
					onClick={() => onChangeHandler(filter.value)}
					key={filter.value}>
					{filter.label}
				</Tabs.Item>
			))}
		</Tabs>
		// <div className="grid grid-cols-2 gap-2 md:grid-cols-4 lg:flex">
		// 	{filters.map((filter) => (
		// 		<button
		// 			type="button"
		// 			onClick={() => onChangeHandler(filter.value)}
		// 			key={filter.value}
		// 			className={classNames('cursor-pointer rounded-full bg-white px-3 py-1 text-sm', {
		// 				'border-2 border-blue-500 font-bold text-blue-500': filterSelected === filter.value
		// 			})}>
		// 			{filter.label}
		// 		</button>
		// 	))}
		// </div>
	);
};

export default FiltersUser;
