import React from 'react';

export interface ISubheaderProps {
	title: string;
	controls?: React.ReactNode;
}

const Subheader = ({ title, controls }: ISubheaderProps) => (
	<div className="my-8 flex flex-col justify-between gap-2 md:flex-row">
		<h2 className="text-2xl font-bold xs:text-xl">{title}</h2>
		{controls && <div>{controls}</div>}
	</div>
);

export default Subheader;
