import { vacationService } from '@services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationDeleteVacation() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (vacationId: number) => vacationService.deleteVacation(vacationId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['MyVacations'] });
		}
	});
}
