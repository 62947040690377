import { HttpMessage } from '@interfaces/apiInterface';
import {
	IMovie,
	IMovieCreditsMDBResponse,
	IMovieTMDBResponse,
	IMoviesTMDBResponse,
	MovieDto
} from '@interfaces/movieInterface';
import { IPagination } from '@interfaces/paginationInterface';
import { apiService } from '@services/index';

import axiosInstance from './interceptor';

export const getMovies = async (page?: number, pageSize?: number, search?: string): Promise<IPagination<IMovie>> => {
	const params = apiService.getQueryParams([
		{ key: 'page', value: page },
		{ key: 'limit', value: pageSize },
		{ key: 'title', value: search }
	]);
	const schedules = await axiosInstance.get<IPagination<IMovie>>(`${import.meta.env.VITE_API_URL}/movie`, { params });
	return schedules.data;
};

export const getMovie = async (movieId: number): Promise<IMovie> => {
	const movie = await axiosInstance.get<IMovie>(`${import.meta.env.VITE_API_URL}/movie/${movieId}`);
	return movie.data;
};

export const getMovieBy = async (movieDBId: number): Promise<IMovie> => {
	const movie = await axiosInstance.get<IMovie>(
		`${import.meta.env.VITE_API_URL}/movie/get-by/?movieDBId=${movieDBId}`
	);
	return movie.data;
};

export const createMovie = async (movieDto: MovieDto): Promise<IMovie> => {
	const movie = await axiosInstance.post<IMovie>(`${import.meta.env.VITE_API_URL}/movie/create`, movieDto);
	return movie.data;
};

export const editMovie = async (movieDto: MovieDto): Promise<IMovie> => {
	const movie = await axiosInstance.patch<IMovie>(`${import.meta.env.VITE_API_URL}/movie/update`, movieDto);
	return movie.data;
};

export const deleteMovie = async (movieId: number): Promise<HttpMessage> => {
	const movie = await axiosInstance.delete<HttpMessage>(`${import.meta.env.VITE_API_URL}/movie/delete/${movieId}`);
	return movie.data;
};

export const getMoviesTMDB = async (getMoviesDto: { title: string; year: number }): Promise<IMoviesTMDBResponse> => {
	const movies = await axiosInstance.post<IMoviesTMDBResponse>(
		`${import.meta.env.VITE_API_URL}/movie/TMDB/movies/`,
		getMoviesDto
	);
	return movies.data;
};

export const getMovieTMDB = async (movieTMDBId: number): Promise<IMovieTMDBResponse> => {
	const movie = await axiosInstance.get<IMovieTMDBResponse>(
		`${import.meta.env.VITE_API_URL}/movie/TMDB/${movieTMDBId}/`
	);
	return movie.data;
};

export const getMovieCreditsTMDB = async (movieTMDBId: number): Promise<IMovieCreditsMDBResponse> => {
	const credits = await axiosInstance.get<IMovieCreditsMDBResponse>(
		`${import.meta.env.VITE_API_URL}/movie/TMDB/${movieTMDBId}/credits/`
	);
	return credits.data;
};
