import { useEffect } from 'react';

import { useQueryNotificationRead } from '@hooks/index';
import { INotificationData, NotificationType } from '@interfaces/notificationInterface';
import { useQueryClient } from '@tanstack/react-query';
import { Badge } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

type NotificationItemProps = {
	className?: string;
	isRead: boolean;
	notificationId: number;
	title: string;
	type: NotificationType;
	data: INotificationData;
};

const NotificationItem = ({ className, isRead, notificationId, title, type, data }: NotificationItemProps) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const { refetch: refetchReadNotification, isSuccess: isSuccessNotification } =
		useQueryNotificationRead(notificationId);

	const onShowNotificationHandler = () => {
		refetchReadNotification();

		if ((type === NotificationType.ExpireSchedule || type === NotificationType.SuggestionAccepted) && data.schedule) {
			navigate(`/planning/${data.schedule.id}`);
		}

		if (type === NotificationType.Suggestion && data.suggestion) {
			navigate(`/suggestions?suggestionId=${data.suggestion.id}`);
		}
	};

	useEffect(() => {
		if (isSuccessNotification) {
			queryClient.invalidateQueries({ queryKey: ['Notifications'] });
		}
	}, [queryClient, isSuccessNotification]);

	return (
		<div className={classNames('flex gap-2 p-3', { 'bg-blue-50': !isRead }, className)}>
			{!isRead && <Badge color="blue" />}
			{isRead && <div className="w-[6px]" />}

			<div className="flex w-full justify-between gap-2">
				<div>
					<div className="font-bold">{title}</div>

					{type === NotificationType.ExpireSchedule && (
						<div className="text-xs text-gray-500">
							Il manque un bénévole pour le film <strong>{data.movie?.title}</strong> le
							<strong> {dayjs(data.schedule?.date).format('dddd D MMM')} </strong>à
							<strong> {dayjs(data.schedule?.date).format('HH:mm')} </strong>
						</div>
					)}

					{type === NotificationType.Suggestion && (
						<div className="text-xs text-gray-500">
							Un bénévole souhaiterait que vous le remplaciez pour le film <strong>{data.movie?.title}</strong>{' '}
							le <strong> {dayjs(data.schedule?.date).format('dddd D MMM')} </strong> à{' '}
							<strong> {dayjs(data.schedule?.date).format('HH:mm')} </strong>
						</div>
					)}

					{type === NotificationType.SuggestionAccepted && (
						<div className="text-xs text-gray-500">
							<strong>{data.userToSuggest?.fullName}</strong> que vous avez proposé pour vous remplacer pour le
							film <strong>{data.movie?.title}</strong> le{' '}
							<strong> {dayjs(data.schedule?.date).format('dddd D MMM')} </strong>à{' '}
							<strong> {dayjs(data.schedule?.date).format('HH:mm')} </strong> a accepté votre demande de
							remplacement.
						</div>
					)}

					{type === NotificationType.SuggestionRejected && (
						<div className="text-xs text-gray-500">
							<strong>{data.userToSuggest?.fullName}</strong> que vous avez proposé pour vous remplacer pour le
							film <strong>{data.movie?.title}</strong> le{' '}
							<strong> {dayjs(data.schedule?.date).format('dddd D MMM')} </strong>à{' '}
							<strong> {dayjs(data.schedule?.date).format('HH:mm')} </strong> a refusé votre demande de
							remplacement.
						</div>
					)}
				</div>
				<div className="flex w-32 justify-center text-xs text-blue-500">
					<button type="button" className="self-center" onClick={() => onShowNotificationHandler()}>
						{(type === NotificationType.ExpireSchedule || type === NotificationType.SuggestionAccepted) && (
							<>Voir film</>
						)}
						{type === NotificationType.Suggestion && <>Voir demande</>}
					</button>
				</div>
			</div>
		</div>
	);
};

export default NotificationItem;
