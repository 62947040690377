import { Suspense } from 'react';

import { StyleProvider } from '@ant-design/cssinjs';
import {
	ChangePassword,
	Inventory,
	InventoryAdd,
	InventoryEdit,
	Login,
	Movies,
	MoviesAdd,
	MoviesEdit,
	MyVacations,
	NotFound,
	Notifications,
	Planning,
	Posters,
	Suggestion,
	Users,
	Vacations
} from '@pages/index';
import { HasAccess, PermifyProvider as PProv } from '@permify/react-role';
import { ConfigProvider, Spin } from 'antd';
import frFR from 'antd/locale/fr_FR';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from './elements';
import { Stats } from './pages/Stats/Stats';

const PermifyProvider = PProv as any;

const antdTheme = {
	token: {
		colorPrimary: 'rgb(37 99 235);',
		colorLink: 'rgb(37 99 235);'
	}
};

const App = () => (
	<Suspense
		fallback={
			<div className="flex h-screen items-center justify-center">
				<div className="flex flex-col gap-4">
					<Spin size="large" />
					<div className="text-xl font-bold">Chargement...</div>
				</div>
			</div>
		}>
		<PermifyProvider>
			<ConfigProvider theme={antdTheme} locale={frFR}>
				<StyleProvider hashPriority="high">
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Navigate replace to="/planning" />} />
							<Route path="/login" element={<Login />} />
							<Route path="/affiches" element={<Posters />} />
							<Route element={<PrivateRoute />}>
								<Route path="/planning" element={<Planning />} />
								<Route path="/planning/:scheduleId" element={<Planning />} />
								<Route path="/users/notifications" element={<Notifications />} />
								<Route path="/my-vacations" element={<MyVacations />} />
								<Route path="/suggestions" element={<Suggestion />} />
								<Route path="/vacations" element={<Vacations />} />
								<Route path="/users/change-password" element={<ChangePassword />} />
								<Route
									path="/movies"
									element={
										<HasAccess roles={['admin']} renderAuthFailed={<NotFound />}>
											<Movies />
										</HasAccess>
									}
								/>
								<Route
									path="/movies/add"
									element={
										<HasAccess roles={['admin']} renderAuthFailed={<NotFound />}>
											<MoviesAdd />
										</HasAccess>
									}
								/>
								<Route
									path="/movies/edit/:movieId"
									element={
										<HasAccess roles={['admin']} renderAuthFailed={<NotFound />}>
											<MoviesEdit />
										</HasAccess>
									}
								/>
								<Route
									path="/users"
									element={
										<HasAccess roles={['admin']} renderAuthFailed={<NotFound />}>
											<Users />
										</HasAccess>
									}
								/>
								<Route
									path="/inventory"
									element={
										<HasAccess roles={['inventory']} renderAuthFailed={<NotFound />}>
											<Inventory />
										</HasAccess>
									}
								/>
								<Route
									path="/inventory/add"
									element={
										<HasAccess roles={['inventory']} renderAuthFailed={<NotFound />}>
											<InventoryAdd />
										</HasAccess>
									}
								/>
								<Route
									path="/inventory/edit/:inventoryId"
									element={
										<HasAccess roles={['inventory']} renderAuthFailed={<NotFound />}>
											<InventoryEdit />
										</HasAccess>
									}
								/>
								<Route
									path="/users/stats"
									element={
										<HasAccess roles={['admin']} renderAuthFailed={<NotFound />}>
											<Stats />
										</HasAccess>
									}
								/>
								<Route path="*" element={<NotFound />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</StyleProvider>
			</ConfigProvider>
		</PermifyProvider>
	</Suspense>
);

export default App;
