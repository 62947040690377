import { useEffect } from 'react';

import { useMutationScheduleUpdate, useQueryUserProfile } from '@hooks/index';
import { ISchedule, IScheduleDto } from '@interfaces/scheduleInterface';
import { IRole, IUser, Role } from '@interfaces/userInterface';
import { Button, Popconfirm, message } from 'antd';
import dayjs from 'dayjs';

import ScheduleReplace from '../ScheduleReplace/ScheduleReplace';

type ScheduleUserProps = {
	user: IUser;
	schedule: ISchedule;
	role: Role;
};

const ScheduleUser = ({ user, schedule, role }: ScheduleUserProps) => {
	const { data: userProfile } = useQueryUserProfile();
	const { mutate: mutateUpdateSchedule, isSuccess: isSuccessMutateUpdateSchedule } = useMutationScheduleUpdate();

	const onSubscribeUser = (roleToCheck: string, userId: number): void => {
		const scheduleParse = {
			...schedule,
			responsable: schedule.responsable ? schedule.responsable.id : null,
			cashier: schedule.cashier ? schedule.cashier.id : null,
			controller: schedule.controller ? schedule.controller.id : null,
			store: schedule.store ? schedule.store.id : null,
			operator: schedule.operator ? schedule.operator.id : null,
			glasses: schedule.glasses ? schedule.glasses.id : null
		};
		const scheduleDto: IScheduleDto = {
			...scheduleParse,
			[roleToCheck]: userId
		};

		mutateUpdateSchedule({ scheduleId: schedule.id, scheduleDto });
	};

	const validateUserRole = (roles: IRole[], roleToCheck: string): boolean =>
		roles.some((roleItem) => roleItem.name === roleToCheck);

	const hasRole = (roles: IRole[], roleToCheck: string): boolean =>
		roles.some((roleItem) => roleItem.name === roleToCheck);

	const userTakeThisSchedule = (): boolean => {
		if (!userProfile) return false;
		if (!schedule) return false;

		return schedule[role] && schedule[role].id === userProfile.id;
	};

	const isPastSchedule = (): boolean => dayjs().isAfter(dayjs(schedule.date));

	useEffect(() => {
		if (isSuccessMutateUpdateSchedule) {
			message.success('Vous êtes correctement inscrit');
		}
	}, [isSuccessMutateUpdateSchedule]);

	return (
		<div className="min-h-[32px]">
			{!user && userProfile && validateUserRole(userProfile.roles, role) && !isPastSchedule() && (
				<Popconfirm
					title="Inscription"
					description="Confirmez-vous l'inscription?"
					okText="Oui"
					cancelText="Non"
					onConfirm={() => onSubscribeUser(role, userProfile.id)}>
					<Button size="small" className="" type="primary">
						S'inscrire
					</Button>
				</Popconfirm>
			)}
			{user && (
				<div className="text-sm">
					{user.firstName} {user.lastName}
				</div>
			)}

			{userProfile && hasRole(userProfile.roles, role) && userTakeThisSchedule() && (
				<ScheduleReplace userSuggester={userProfile.id} role={role} scheduleId={schedule.id} />
			)}
		</div>
	);
};

export default ScheduleUser;
