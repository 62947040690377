import React, { useState } from 'react';

import { IconXCircle } from '../../icons';
import { DatePickerRange } from '../DatePickerRange/DatePickerRange';

type DateRangeProps = {
	onCloseDropdown?: () => void;
	onChange: (value: [string | undefined, string | undefined]) => void;
	value?: [string | undefined, string | undefined];
};

export const DateRangeFilter = ({ onCloseDropdown, onChange, value }: DateRangeProps) => {
	const [open, setOpen] = useState(false);

	return (
		<DatePickerRange
			renderTrigger={({ date, handlerClearInput }) => (
				<div className="flex h-8 min-w-[320px] items-center justify-between gap-2 rounded border border-solid border-slate-400 bg-white px-2 py-1 text-sm">
					<button type="button" onClick={() => setOpen(!open)}>
						<strong>Date:</strong> {date}
					</button>
					{date && (
						<button className="flex justify-end text-slate-500" type="button" onClick={() => handlerClearInput()}>
							<IconXCircle width={16} />
						</button>
					)}
				</div>
			)}
			showQuickSelection
			onCloseDropdown={onCloseDropdown}
			onChange={onChange}
			value={value}
		/>
	);
};
