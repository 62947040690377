import { DATE_DAYJS_FORMAT } from '@enums/dates';
import dayjs from 'dayjs';
import { ArrowLeft, ArrowRight } from 'react-feather';

type PlanningMonthPickerProps = {
	date: string;
	onChangeDate: (date: string) => void;
};

const PlanningMonthPicker = ({ date, onChangeDate }: PlanningMonthPickerProps) => (
	<div className="flex w-full justify-between md:justify-center">
		<button type="button" onClick={() => onChangeDate(dayjs(date).subtract(1, 'month').format(DATE_DAYJS_FORMAT.US))}>
			<ArrowLeft />
		</button>
		<div className="flex justify-center text-lg font-bold capitalize md:w-80">{dayjs(date).format('MMMM YYYY')}</div>
		<button type="button" onClick={() => onChangeDate(dayjs(date).add(1, 'month').format(DATE_DAYJS_FORMAT.US))}>
			<ArrowRight />
		</button>
	</div>
);

export default PlanningMonthPicker;
