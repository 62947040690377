import { useEffect, useState } from 'react';

import { PasswordStrengthMeter } from '@components/index';
import { Container } from '@elements/index';
import { useQueryUserProfile } from '@hooks/index';
import useMutationResetPassword from '@hooks/users/useMutationResetPassword';
import { Status } from '@interfaces/apiInterface';
import { ChangePasswordDto } from '@interfaces/userInterface';
import { authService } from '@services/index';
import { Alert, Button, Input, Spin, message } from 'antd';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, ref, string } from 'yup';

const ChangePassword = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	const { data: userProfile } = useQueryUserProfile();

	const {
		mutate: mutateResetPassword,
		isPending: isLoadingResetPassword,
		status: statusResetPassword
	} = useMutationResetPassword();

	const onSubmitHandler = async (values: ChangePasswordDto) => {
		mutateResetPassword(values);
	};

	// Form
	const { touched, errors, getFieldProps, handleSubmit, isValid, values } = useFormik({
		initialValues: {
			passwordConfirmation: '',
			password: ''
		},
		onSubmit: onSubmitHandler,
		validationSchema: object({
			password: string().required('Mot de passe requis'),
			passwordConfirmation: string().oneOf(
				[ref('password')],
				'Le mot de passe doit être identique dans les deux champs.'
			)
		})
	});

	useEffect(() => {
		if (userProfile) {
			setLoading(false);
		}

		if (userProfile && userProfile.passwordChangeed === 1) {
			navigate('/planning');
		}
	}, [userProfile]);

	useEffect(() => {
		if (statusResetPassword === Status.Success) {
			message.success(
				'Le nouveau mot de passe a été correctement enregistré, veuillez vous connecter avec votre nouveau mot de passe.'
			);
			authService.logout();
			navigate('/login');
		}

		if (statusResetPassword === Status.Error) {
			message.warning('Une erreur est survenue, veuillez réessayer plus tard.');
		}
	}, [statusResetPassword]);

	return (
		<Container>
			{!loading && userProfile && (
				<div className="flex h-screen items-center justify-center">
					<div className="flex w-col-90 flex-col gap-4 md:w-col-70 lg:w-col-50 xl:w-col-40">
						<h1 className="mb-5 text-center text-2xl font-bold">Cinéma Le Cep - Vallet</h1>
						<div className="flex flex-col gap-4 rounded-lg bg-white p-6 shadow">
							<Alert
								message="Changement de mot de passe nécessaire"
								description="Pour pouvoir accéder au planning vous devez tout d'abord changer votre mot de passe."
								type="info"
								showIcon
							/>

							<form noValidate autoComplete="off" onSubmit={handleSubmit}>
								<div className="flex flex-col gap-4">
									<div className="flex flex-col gap-2">
										<div className="">
											<label htmlFor="password" className="block text-sm font-semibold text-slate-600">
												Nouveau mot de passe
											</label>
											<small className=" text-slate-500">
												Sécurisez votre compte dès maintenant ! Créez un mot de passe solide pour protéger
												vos informations. Assurez-vous qu'il contienne au moins 8 caractères, y compris des
												lettres majuscules, des chiffres et des caractères spéciaux.
											</small>
										</div>

										<PasswordStrengthMeter password={values.password} />

										<Input.Password
											type="password"
											status={touched.password && errors.password ? 'error' : ''}
											id="password"
											{...getFieldProps('password')}
										/>
										{touched.password && errors.password && (
											<small className="text-xs text-red-500">{errors.password}</small>
										)}
									</div>
									<div className="mb-5">
										<label
											htmlFor="passwordConfirmation"
											className="block pb-1 text-sm font-semibold text-slate-600">
											Confirmez le nouveau mot de passe
										</label>
										<Input.Password
											type="password"
											status={touched.passwordConfirmation && errors.passwordConfirmation ? 'error' : ''}
											id="passwordConfirmation"
											{...getFieldProps('passwordConfirmation')}
										/>

										{touched.passwordConfirmation && errors.passwordConfirmation && (
											<small className="text-xs text-red-500">{errors.passwordConfirmation}</small>
										)}
									</div>
									<Button
										block
										size="large"
										loading={isLoadingResetPassword}
										disabled={!isValid}
										type="primary"
										htmlType="submit">
										Enregistrer le nouveau mot de passe
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}

			{loading && (
				<div className="flex h-screen items-center justify-center">
					<Spin />
				</div>
			)}
		</Container>
	);
};

export default ChangePassword;
