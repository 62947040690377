import React, { useState } from 'react';

import { Button } from 'antd';
import dayjs from 'dayjs';

import { DateRangeFilter } from '../../components/Filters/DateRangeFilter';
import { Container, Header, Subheader } from '../../elements';
import useQueryScheduleParticipationByUser from '../../hooks/schedules/useQueryScheduleParticipationByUser';
import useQueryScheduleParticipationCSV from '../../hooks/schedules/useQueryScheduleParticipationCSV';
import { QueryParametersParticipationsUser } from '../../interfaces/scheduleInterface';

export const Stats = () => {
	const [parms, setParams] = useState<QueryParametersParticipationsUser>({
		startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
		endDate: dayjs().endOf('year').format('YYYY-MM-DD'),
		orderBy: {
			total: 'DESC'
		}
	});
	const { data: users } = useQueryScheduleParticipationByUser(parms);
	const { refetch: refetchCSV } = useQueryScheduleParticipationCSV(parms);

	const downloadCsv = async () => {
		try {
			const blob = await refetchCSV();
			if (blob && blob.data) {
				const url = window.URL.createObjectURL(blob.data);
				const a = document.createElement('a');
				a.href = url;
				a.download = `participation_[${parms.startDate}_${parms.endDate}].csv`;

				document.body.appendChild(a);
				a.click();
				a.remove();
				window.URL.revokeObjectURL(url);
			} else {
				console.error('No data available to download.');
			}
		} catch (error) {
			console.error('Error downloading the CSV file:', error);
		}
	};

	return (
		<div>
			<Header />
			<Container>
				<Subheader
					title="Rapport d'assistances"
					controls={
						<Button type="primary" htmlType="button" onClick={() => downloadCsv()}>
							Télécharger le rapport
						</Button>
					}
				/>

				<div className="flex flex-col gap-5">
					<div>
						<h3 className="font-bold">Filtres</h3>
						<div className="flex">
							<DateRangeFilter
								onChange={(value) =>
									setParams((params) => ({
										...params,
										startDate: value[0],
										endDate: value[1]
									}))
								}
								value={[parms.startDate, parms.endDate]}
							/>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
						{users &&
							users.map((user) => (
								<div
									key={user.id}
									className="flex flex-row items-center justify-between gap-2 rounded bg-white p-4">
									<div className="text-lg font-bold">{user.fullName}</div>
									<div className="flex gap-2 text-lg">
										<div className="font-bold">{user.total}</div>
										<div className="text-slate-500">Séances</div>
									</div>
								</div>
							))}
					</div>
				</div>
			</Container>
		</div>
	);
};
