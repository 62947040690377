import { useEffect, useState } from 'react';

import { DatePickerRange } from '@components/DatePickerRange/DatePickerRange';
import { Empty } from '@components/index';
import { Container, Header, Subheader } from '@elements/index';
import { DATE_DAYJS_FORMAT } from '@enums/dates';
import { useMutationDeleteVacation, useMutationVacation, useQueryMyVacations } from '@hooks/index';
import { Button, Popconfirm, message } from 'antd';
import dayjs from 'dayjs';
import 'react-day-picker/dist/style.css';
import { Trash2 } from 'react-feather';

const MyVacations = () => {
	const [value, setValue] = useState<{ startDate: string | null; endDate: string | null }>({
		startDate: null,
		endDate: null
	});

	const { mutate: mutateVacation, isSuccess: isSuccessCreateVacation } = useMutationVacation();
	const { mutate: mutateDeleteVacation, isSuccess: isSuccessDeleteVacation } = useMutationDeleteVacation();

	const { data: myVacations } = useQueryMyVacations();

	const onSubmitHandler = () => {
		if (!value?.startDate || !value?.endDate) return;

		mutateVacation({
			startDate: value?.startDate as string,
			endDate: value?.endDate as string
		});
		setValue({
			startDate: null,
			endDate: null
		});
	};

	const onDeleteHandler = (vacationId: number) => {
		if (!vacationId) return;

		mutateDeleteVacation(vacationId);
	};

	useEffect(() => {
		if (isSuccessCreateVacation) {
			message.success("L'indisponibilité a été correctement enregistrée.");
		}
	}, [isSuccessCreateVacation]);

	useEffect(() => {
		if (isSuccessDeleteVacation) {
			message.success("L'indisponibilité a été correctement éliminée.");
		}
	}, [isSuccessDeleteVacation]);

	return (
		<>
			<Header />
			<Container>
				<Subheader title="Mes indisponibilités" />

				<div className="flex flex-col gap-4 md:grid md:grid-cols-5 md:gap-2">
					<div className="col-span-2">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Ajouter une indisponibilité</h3>
						<div className="flex flex-col gap-2">
							<div className="rounded-lg bg-white p-4">
								<form noValidate>
									<div className="col-span-3">
										<label className="font-bold" htmlFor="title">
											Date
										</label>
										<DatePickerRange
											disablePast
											onChange={(dates) =>
												setValue({ startDate: dates[0] as string, endDate: dates[1] as string })
											}
											value={[value.startDate as string, value.endDate as string]}
										/>
									</div>
									<div className="mt-2 flex items-end">
										<Button
											disabled={!value?.endDate || !value.startDate}
											onClick={onSubmitHandler}
											block
											type="primary"
											htmlType="button">
											Enregistrer l&apos;indisponibilité
										</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-span-3">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Indisponibilités enregistrées</h3>
						{myVacations && (
							<div className="flex flex-col gap-2">
								{myVacations.map((vacation) => (
									<div
										key={vacation.id}
										className="grid grid-cols-12 items-center gap-4 rounded-lg bg-white p-4">
										<div className="col-span-5">
											<div className="font-bold">Date de début</div>
											<div className="text-sm">
												{dayjs(vacation.startDate).format(DATE_DAYJS_FORMAT.FR_LONG)}
											</div>
										</div>
										<div className="col-span-5">
											<div className="font-bold">Date de fin</div>
											<div className="text-sm">
												{dayjs(vacation.endDate).format(DATE_DAYJS_FORMAT.FR_LONG)}
											</div>
										</div>
										<div className="col-span-2 flex items-center justify-center text-red-500">
											<Popconfirm
												title="Supprimer l'indisponibilité"
												description="Confirmez-vous la suppression ?"
												onConfirm={() => onDeleteHandler(vacation.id)}
												okText="Confirmer"
												cancelText="Annuler">
												<button type="button">
													<Trash2 size={16} />
												</button>
											</Popconfirm>
										</div>
									</div>
								))}
							</div>
						)}

						{myVacations?.length === 0 && (
							<div className="rounded-lg bg-white p-4">
								<div className="flex items-center justify-center">
									<Empty title="Aucun résultat" description="Pas d'indisponibilité enregistrée" />
								</div>
							</div>
						)}
					</div>
				</div>
			</Container>
		</>
	);
};

export default MyVacations;
