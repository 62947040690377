import { vacationService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryMyVacations() {
	return useQuery({
		queryKey: ['MyVacations'],
		queryFn: () => vacationService.getUserVacations()
		// enabled: false,
	});
}
