const IconMovieCards = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="148" height="170" fill="none" viewBox="0 0 267 305">
		<g filter="url(#a)">
			<rect width="166" height="255" x="92" y="5" fill="#fff" rx="8" />
		</g>
		<path fill="#F1F5F9" d="M92 13a8 8 0 0 1 8-8h150a8 8 0 0 1 8 8v198H92V13Z" />
		<path fill="#E2E8F0" d="M103 218h144v13H103zM118 234h114v13H118z" />
		<path
			fill="#001A72"
			d="M146.625 90.854a3.207 3.207 0 0 1 3.208-3.208h35.292a3.21 3.21 0 0 1 3.208 3.208v35.292a3.208 3.208 0 0 1-3.208 3.208h-35.292a3.204 3.204 0 0 1-2.268-.94 3.204 3.204 0 0 1-.94-2.268V90.854Z"
			opacity=".15"
		/>
		<path
			stroke="#001A72"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="3"
			d="m188.333 98.875 16.042-6.417v32.084l-16.042-6.417m-38.5 11.229h35.292a3.209 3.209 0 0 0 3.208-3.208V90.854a3.21 3.21 0 0 0-3.208-3.208h-35.292a3.205 3.205 0 0 0-3.208 3.208v35.292c0 .851.338 1.667.94 2.268.601.602 1.417.94 2.268.94Z"
		/>
		<g filter="url(#b)">
			<rect width="166" height="255" x="9" y="37" fill="#fff" rx="8" />
		</g>
		<path fill="#F1F5F9" d="M9 45a8 8 0 0 1 8-8h150a8 8 0 0 1 8 8v198H9V45Z" />
		<path fill="#E2E8F0" d="M20 250h144v13H20zM35 266h114v13H35z" />
		<path
			fill="#001A72"
			d="M63.625 122.854a3.205 3.205 0 0 1 3.208-3.208h35.292a3.209 3.209 0 0 1 3.208 3.208v35.292a3.208 3.208 0 0 1-3.208 3.208H66.833a3.207 3.207 0 0 1-3.208-3.208v-35.292Z"
			opacity=".15"
		/>
		<path
			stroke="#001A72"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="3"
			d="m105.333 130.875 16.042-6.417v32.084l-16.042-6.417m-38.5 11.229h35.292a3.209 3.209 0 0 0 3.208-3.208v-35.292a3.208 3.208 0 0 0-3.208-3.208H66.833a3.207 3.207 0 0 0-3.208 3.208v35.292a3.205 3.205 0 0 0 3.208 3.208Z"
		/>
		<defs>
			<filter id="a" width="184" height="273" x="83" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feMorphology in="SourceAlpha" operator="dilate" radius="1" result="effect1_dropShadow_2_246" />
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="4" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_246" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_2_246" result="shape" />
			</filter>
			<filter id="b" width="184" height="273" x="0" y="32" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
				<feMorphology in="SourceAlpha" operator="dilate" radius="1" result="effect1_dropShadow_2_246" />
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="4" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_246" />
				<feBlend in="SourceGraphic" in2="effect1_dropShadow_2_246" result="shape" />
			</filter>
		</defs>
	</svg>
);

export default IconMovieCards;
