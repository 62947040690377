import { notificationService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryNotificationsReadAll() {
	return useQuery({
		queryKey: ['NotificationsReadAll'],
		queryFn: () => notificationService.readAllNotifications(),
		enabled: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false
	});
}
