import classNames from 'classnames';

export interface ISkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
	height: number;
	width: number | string;
	animate?: boolean;
}

const Skeleton = ({ height, width, animate = true }: ISkeletonProps) => (
	<div
		className={classNames('rounded-lg bg-slate-50', {
			'animate-pulse': animate
		})}
		style={{ width, height }}
	/>
);

export default Skeleton;
