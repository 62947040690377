import { UserDto } from '@interfaces/userInterface';
import { userService } from '@services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationEditUser() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ userId, userDto }: { userId: number; userDto: UserDto }) =>
			userService.updateUser(userId, userDto),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['Users'] });
		}
	});
}
