import { HTMLAttributes } from 'react';

import classNames from 'classnames';

import TabItem from './TabItem';

export type ITabsProps = HTMLAttributes<HTMLDivElement>;

const Tabs = ({ children, className }: ITabsProps) => (
	<div className={classNames('block rounded-lg bg-white p-1 shadow ', className)}>{children}</div>
);

Tabs.Item = TabItem;

export default Tabs;
