import { NotificationQueryParameters } from '@interfaces/notificationInterface';
import { notificationService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryNotifications({ page, limit }: NotificationQueryParameters) {
	return useQuery({
		queryKey: ['Notifications', page, limit],
		queryFn: () => notificationService.getNotifications({ page, limit }),
		staleTime: 0
	});
}
