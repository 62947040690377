import React, { useEffect, useState } from 'react';

import { DATE_DAYJS_FORMAT } from '@enums/dates';
import { useClickOutside } from '@hooks/index';
import { Input, Popover } from 'antd';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { fr } from 'date-fns/locale';
import dayjs from 'dayjs';
import { DayPicker, DayModifiers } from 'react-day-picker';
import { XCircle } from 'react-feather';

import './DatePicker.css';
import { HeaderDatePicker } from './HeaderDatePicker';

type DatePickerRangeProps = {
	renderTrigger?: ({ date, handlerClearInput }: { date: string; handlerClearInput: () => void }) => React.ReactNode;
	onCloseDropdown?: () => void;
	onChange: (value: [string | undefined, string | undefined]) => void;
	value?: [string | undefined, string | undefined];
	showQuickSelection?: boolean;
	disablePast?: boolean;
};

export const DatePickerRange = ({
	showQuickSelection = false,
	renderTrigger,
	disablePast = false,
	onCloseDropdown,
	onChange,
	value
}: DatePickerRangeProps) => {
	const [fromDate, setFromDate] = useState<Date | undefined>();
	const [toDate, setToDate] = useState<Date | undefined>();

	const [open, setOpen] = useState(false);

	const { ref } = useClickOutside(open, setOpen);

	const hanndleDayClick = (day: Date, modiefiers: DayModifiers) => {
		if (modiefiers.disabled) return;

		if (fromDate === undefined) {
			setFromDate(day);
		} else if (toDate === undefined) {
			if (day < fromDate) {
				setToDate(fromDate);
				setFromDate(day);
			} else {
				setToDate(day);

				if (onCloseDropdown) {
					onCloseDropdown();
				}
			}
		} else {
			setFromDate(day);
			setToDate(undefined);
		}
	};

	const parseDate = () => {
		const formattedFromDate = fromDate ? dayjs(fromDate).format(DATE_DAYJS_FORMAT.FR_SEMI_LONG) : '';
		const formattedToDate = toDate ? dayjs(toDate).format(DATE_DAYJS_FORMAT.FR_SEMI_LONG) : '';
		return formattedFromDate && formattedToDate
			? `${formattedFromDate}   >   ${formattedToDate}`
			: `${formattedFromDate}${formattedToDate}`;
	};

	const parsedDate = parseDate();

	const handlerClearInput = () => {
		setFromDate(undefined);
		setToDate(undefined);
	};

	useEffect(() => {
		if (fromDate || toDate) {
			onChange([
				fromDate ? format(fromDate as Date, 'yyyy-MM-dd') : undefined,
				toDate ? format(toDate as Date, 'yyyy-MM-dd') : undefined
			]);
		}

		if (fromDate && toDate) {
			setOpen(false);
		}
	}, [fromDate, toDate]);

	useEffect(() => {
		if (value) {
			const [newFromDate, newToDate] = value;
			const newFromDateObj = newFromDate ? dayjs(newFromDate).toDate() : undefined;
			const newToDateObj = newToDate ? dayjs(newToDate).toDate() : undefined;

			if (newFromDateObj?.getTime() !== fromDate?.getTime() || newToDateObj?.getTime() !== toDate?.getTime()) {
				setFromDate(newFromDateObj);
				setToDate(newToDateObj);
			}
		}
	}, [value]);

	return (
		<Popover
			trigger="click"
			open={open}
			content={
				<div ref={ref} className="flex flex-row gap-4">
					{showQuickSelection && (
						<div className="solid flex flex-col items-start gap-4 border-r pr-4">
							<button
								className="select-none rounded-md bg-slate-100 px-2 py-1 text-sm font-semibold"
								type="button"
								onClick={() => {
									setFromDate(dayjs().startOf('month').toDate());
									setToDate(dayjs().endOf('month').toDate());
								}}>
								Ce mois-ci
							</button>
							<button
								onClick={() => {
									setFromDate(dayjs().subtract(1, 'month').startOf('month').toDate());
									setToDate(dayjs().subtract(1, 'month').endOf('month').toDate());
								}}
								className="select-none rounded-md bg-slate-100 px-2 py-1 text-sm font-semibold"
								type="button">
								Le mois dernier
							</button>
							<button
								onClick={() => {
									setFromDate(dayjs().startOf('year').toDate());
									setToDate(dayjs().endOf('year').toDate());
								}}
								className="select-none rounded-md bg-slate-100 px-2 py-1 text-sm font-semibold"
								type="button">
								Cette année
							</button>
							<button
								onClick={() => {
									setFromDate(dayjs().subtract(1, 'year').startOf('year').toDate());
									setToDate(dayjs().subtract(1, 'year').endOf('year').toDate());
								}}
								className="select-none rounded-md bg-slate-100 px-2 py-1 text-sm font-semibold"
								type="button">
								L&apos;année dernière
							</button>
						</div>
					)}
					<DayPicker
						modifiers={disablePast ? { disabled: { before: dayjs().startOf('day').toDate() } } : undefined}
						components={{
							Caption: HeaderDatePicker
						}}
						locale={fr}
						pagedNavigation
						modifiersClassNames={{
							booked: 'bg-blue-500 text-white',
							disabled: 'text-slate-700 cursor-not-allowed',
							range_end: 'bg-blue-600 text-white rounded-none range-end hover:bg-blue-700 rounded-r-lg',
							range_middle: 'rounded-none bg-blue-300 hover:bg-blue-300 text-slate-900',
							range_start: 'bg-blue-600 rounded-none text-white rounded-l-lg range-start hover:bg-blue-700',
							today: 'font-semibold after:absolute after:h-[5px] after:w-[5px] after:translate-y-[12px] after:translate-x-[0px] after:rounded-full after:bg-blue-600 after:content-[""]'
						}}
						onDayClick={hanndleDayClick}
						mode="range"
						selected={{ from: fromDate, to: toDate }}
					/>
				</div>
			}>
			{renderTrigger && (
				<div
					tabIndex={0}
					onClick={() => setOpen(true)}
					onKeyDown={(e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							setOpen(true);
						}
					}}
					role="button">
					{renderTrigger({ date: parsedDate, handlerClearInput })}
				</div>
			)}
			{!renderTrigger && (
				<Input
					readOnly
					onClick={() => setOpen(true)}
					value={parseDate()}
					suffix={
						parseDate().length > 0 && (
							<button
								onClick={() => handlerClearInput()}
								type="button"
								className="flex items-center text-slate-300">
								<XCircle size={18} />
							</button>
						)
					}
				/>
			)}
		</Popover>
	);
};
