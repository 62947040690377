import { useEffect } from 'react';

import { useMutationEditUser, useQueryUser } from '@hooks/index';
import useMutationResetPasswordByDefaultUser from '@hooks/users/useMutationResetPasswordByDefaultUser';
import { Status } from '@interfaces/apiInterface';
import { UserDto } from '@interfaces/userInterface';
import { Checkbox, Input, Modal, Popconfirm, message } from 'antd';
import { useFormik } from 'formik';
import { array, number, object, string } from 'yup';

import { rolesList } from '../ModalUserAdd/ModalUserAdd';

type ModalUserAddProps = {
	open: boolean;
	onOpen: (open: boolean) => void;
	userToUpdate: number;
};

const ModalUserEdit = ({ open, onOpen, userToUpdate }: ModalUserAddProps) => {
	const { data: user } = useQueryUser(userToUpdate);
	const { mutate: mutateEditUser, status } = useMutationEditUser();
	const { mutate: mutateResetPasswordUser, status: statusResetPasswordUser } = useMutationResetPasswordByDefaultUser();

	const onSubmitHandler = async (values: UserDto) => {
		mutateEditUser({ userId: userToUpdate, userDto: values });
	};

	// Form
	const { values, setValues, setFieldValue, isValid, dirty, resetForm, handleSubmit, getFieldProps, errors, touched } =
		useFormik<UserDto>({
			initialValues: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				movil: '',
				roles: []
			},
			onSubmit: onSubmitHandler,
			validationSchema: object({
				firstName: string().required('Veuillez renseigner un prénom'),
				lastName: string().required('Veuillez renseigner un nom'),
				email: string().required('Adresse mail requise').email('Veuillez renseigner une adresse mail valide.'),
				phone: string().required('Veuillez renseigner un numéro de téléphone'),
				movil: string().required('Veuillez renseigner un numéro de téléphone portable'),
				roles: array().of(number()).min(1, 'Veuillez renseigner au moins un rôle')
			})
		});

	const onCancel = () => {
		resetForm();
		onOpen(false);
	};

	const onResetPassword = (userId: number) => {
		mutateResetPasswordUser({ userId });
	};

	useEffect(() => {
		if (status === Status.Success) {
			message.success('Benévole correctement modifié.');
			onOpen(false);
		}

		if (status === Status.Error) {
			message.warning('Une erreur est survenue, veuillez réessayer plus tard.');
			onOpen(false);
		}
	}, [status]);

	useEffect(() => {
		if (statusResetPasswordUser === Status.Success) {
			message.success('Mot de passe correctement réinitialisé.');
			onOpen(false);
		}

		if (statusResetPasswordUser === Status.Error) {
			message.warning('Une erreur est survenue, veuillez réessayer plus tard.');
			onOpen(false);
		}
	}, [statusResetPasswordUser]);

	useEffect(() => {
		if (user) {
			setValues({
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phone: user.phone,
				movil: user.movil,
				roles: user.roles.map((role) => role.id)
			});
		}
	}, [user]);

	return (
		<Modal
			centered
			title="Modifier le bénévole"
			open={open}
			onOk={() => handleSubmit()}
			cancelText="Annuler"
			okText="Enregistrer"
			onCancel={onCancel}
			okButtonProps={{ disabled: !(isValid && dirty) }}>
			<>
				<form noValidate autoComplete="off">
					<div className="flex flex-col gap-4 py-4">
						<div>
							<label className="font-bold" htmlFor="firstName">
								Prénom
							</label>
							<Input
								status={touched.firstName && errors.firstName ? 'error' : undefined}
								id="firstName"
								{...getFieldProps('firstName')}
							/>
							{touched.firstName && errors.firstName && (
								<small className="text-red-500">{errors.firstName}</small>
							)}
						</div>
						<div>
							<label className="font-bold" htmlFor="lastName">
								Nom
							</label>
							<Input
								status={touched.lastName && errors.lastName ? 'error' : undefined}
								id="lastName"
								{...getFieldProps('lastName')}
							/>
							{touched.lastName && errors.lastName && <small className="text-red-500">{errors.lastName}</small>}
						</div>
						<div>
							<label className="font-bold" htmlFor="email">
								Email
							</label>
							<Input
								status={touched.email && errors.email ? 'error' : undefined}
								id="email"
								{...getFieldProps('email')}
							/>
							{touched.email && errors.email && <small className="text-red-500">{errors.email}</small>}
						</div>
						<div className="flex gap-4">
							<div className="w-6/12">
								<label className="font-bold" htmlFor="phone">
									Téléphone
								</label>
								<Input
									status={touched.phone && errors.phone ? 'error' : undefined}
									id="phone"
									{...getFieldProps('phone')}
								/>
								{touched.phone && errors.phone && <small className="text-red-500">{errors.phone}</small>}
							</div>
							<div className="w-6/12">
								<label className="font-bold" htmlFor="movil">
									Téléphone portable
								</label>
								<Input
									status={touched.movil && errors.movil ? 'error' : undefined}
									id="movil"
									{...getFieldProps('movil')}
								/>
								{touched.movil && errors.movil && <small className="text-red-500">{errors.movil}</small>}
							</div>
						</div>
						<div>
							<label className="font-bold" htmlFor="roles">
								Rôles
							</label>
							<Checkbox.Group
								value={values.roles}
								className="!grid grid-cols-2 gap-4 md:grid-cols-3"
								onChange={(roles) => setFieldValue('roles', roles)}>
								{rolesList.map((role) => (
									<Checkbox className="!me-0 !ms-0" key={role.value} value={role.value}>
										{role.label}
									</Checkbox>
								))}
							</Checkbox.Group>
						</div>
					</div>
				</form>

				<Popconfirm
					title="Réinitialisation du mot de passe"
					description="Confirmez-vous la réinitialisation du mot de passe ?"
					onConfirm={() => onResetPassword(userToUpdate)}
					okText="Confirmer"
					cancelText="Annuler">
					<button className="my-4 font-bold text-slate-500 underline" type="button">
						Réinitialiser le mot de passe
					</button>
				</Popconfirm>
			</>
		</Modal>
	);
};

export default ModalUserEdit;
