import { IRole } from './userInterface';

export interface IAuthDto {
	email: string;
	password: string;
}

export interface ILoginResponse {
	access_token: string;
	userEmail: string;
	passwordChangeed: number;
}

export interface IUserChangePasswordDto {
	userId: number;
	password: string;
	passwordVerify: string;
}

export enum LocalToken {
	TOKEN = 'token',
	REFRESH = 'refresh'
}

export interface IUserProfile {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	status: string;
	roles: IRole[];
	passwordChangeed: number;
}
