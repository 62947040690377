import { userService } from '@services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationDeleteUser() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ userId }: { userId: number }) => userService.deleteUser(userId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['Users'] });
		}
	});
}
