import { useEffect, useState } from 'react';

import { AddItem, Empty, Skeleton } from '@components/index';
import { Container, Header, MovieTMDBSearch, Subheader } from '@elements/index';
import { MovieTMDBSearchType, MovieType } from '@elements/Movie/types';
import { useMutationDeleteSchedule, useMutationEditMovie, useQueryMoviesGetBy, useQueryMovieTMDB } from '@hooks/index';
import useMutationDeleteMovie from '@hooks/movies/useMutationDeleteMovie';
import useQueryMovie from '@hooks/movies/useQueryMovie';
import { IconMovieCards } from '@icons/index';
import { Status } from '@interfaces/apiInterface';
import { IMovieScheduleDto, IMovieTMDB, MovieUpdateDto } from '@interfaces/movieInterface';
import { Button, Popconfirm, message } from 'antd';
import { useFormik } from 'formik';
import { PlusCircle, Trash2 } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import MovieScheduleItem from './MovieScheduleItem';

type FieldName = keyof IMovieScheduleDto;

const MovieEdit = () => {
	const { movieId } = useParams();
	const navigate = useNavigate();

	const [title, setTitle] = useState('');
	const [year, setYear] = useState('');

	const [selectMovieTMDB, setSelectMovieTMDB] = useState<IMovieTMDB>();

	const { data: movie, isLoading: isLoadingMovie } = useQueryMovie(Number(movieId));
	const { data: movieTMDB, isFetched: isFetchedMovieTMDB } = useQueryMovieTMDB({
		movieTMDBId: movie?.movieDBId as number,
		config: { enabled: !!movie?.movieDBId && movie.movieDBId !== null }
	});

	const { mutate: mutateMovie, status: mutationMovieStatus } = useMutationEditMovie();
	const { mutate: mutateDeleteSchedule } = useMutationDeleteSchedule();
	const { mutate: mutateDeleteMovie, status: statusDeleteMovie } = useMutationDeleteMovie();
	const { data: movieInDatabase } = useQueryMoviesGetBy(selectMovieTMDB?.id as number);

	const onSubmitHandler = async (values: MovieUpdateDto) => {
		mutateMovie(values);
	};

	// Form
	const { values, setValues, isValid, dirty, handleSubmit } = useFormik<MovieUpdateDto>({
		initialValues: {
			id: 0,
			title: '',
			movieDBId: null,
			poster_path: '',
			schedules: [{ date: '', type: [] }]
		},
		onSubmit: onSubmitHandler,
		validationSchema: Yup.object({
			title: Yup.string().required('El titulo es requerido'),
			schedules: Yup.array().of(
				Yup.object({
					date: Yup.string().required('La fecha y hora es requerida'),
					type: Yup.array().min(1, 'Minimo un tipo debe ser seleccionado')
				})
			)
		})
	});

	const handlerMovieSelected = (movieTMDBSelected: IMovieTMDB): void => {
		setValues((prevValues) => ({
			...prevValues,
			movieDBId: movieTMDBSelected.id ? Number(movieTMDBSelected.id) : null,
			poster_path: movieTMDBSelected.poster_path
		}));
	};

	const updateScheduleField = <T extends FieldName>(
		fieldName: T,
		value: IMovieScheduleDto[T],
		index: number
	): void => {
		setValues((prevValues) => {
			const { schedules } = prevValues;
			const newSchedules = [...schedules];
			const item = { ...newSchedules[index] };
			item[fieldName] = value;
			newSchedules[index] = item;
			return { ...prevValues, schedules: newSchedules };
		});
	};

	const addScheduleItem = () => {
		setValues((prevValues) => ({
			...prevValues,
			schedules: [...prevValues.schedules, { date: '', type: [] }]
		}));
	};

	const onDeleteScheduleItem = (index: number, scheduleId: number) => {
		setValues((prevValues) => {
			const { schedules } = prevValues;
			const newSchedules = schedules.filter((_, i) => i !== index);
			return { ...prevValues, schedules: newSchedules };
		});

		mutateDeleteSchedule({ scheduleId });
	};

	const onDeleteMovie = (movieToDelte: number) => {
		mutateDeleteMovie({ movieId: movieToDelte });
	};

	useEffect(() => {
		setSelectMovieTMDB(undefined);
	}, []);

	useEffect(() => {
		if (movieTMDB && movie && Number(movie.movieDBId) === movieTMDB.id) {
			setSelectMovieTMDB(movieTMDB);
		} else {
			setSelectMovieTMDB(undefined);
		}
	}, [movieTMDB]);

	useEffect(() => {
		if (movie) {
			setTitle(movie.title);
			setValues({
				id: movie.id,
				title: movie.title,
				movieDBId: movie.movieDBId,
				poster_path: movie.poster_path,
				schedules: movie.schedules
			});
		}
	}, [movie]);

	useEffect(() => {
		if (!title) return;

		setValues((prevValues) => ({
			...prevValues,
			title: title as string
		}));
	}, [title]);

	useEffect(() => {
		if (mutationMovieStatus === Status.Error) {
			message.error('Une erreur est survenue, veuillez réessayer plus tard.');
		}
		if (mutationMovieStatus === Status.Success) {
			message.success('Le film a été correctement modifié.');
			navigate('/movies');
		}
	}, [mutationMovieStatus]);

	useEffect(() => {
		if (statusDeleteMovie === Status.Error) {
			message.error('Une erreur est survenue, veuillez réessayer plus tard.');
		}
		if (statusDeleteMovie === Status.Success) {
			message.success('Le film a été correctement supprimé.');
			navigate('/movies');
		}
	}, [statusDeleteMovie]);

	return (
		<>
			<Header />
			<Container>
				<Subheader title="Editer un film" />

				<div className="flex flex-col gap-2 md:grid md:grid-cols-5">
					<div className="md:col-span-2">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Information du film</h3>
						<div className="flex flex-col gap-2">
							<MovieTMDBSearch
								type={MovieType.Movie}
								movieInDatabase={movieInDatabase}
								mode={MovieTMDBSearchType.Edit}
								title={title as string}
								year={year as string}
								setTitle={setTitle}
								setYear={setYear}
								selectMovieTMDB={selectMovieTMDB as IMovieTMDB}
								setSelectMovieTMDB={setSelectMovieTMDB}
								handlerMovieSelected={handlerMovieSelected}
								isLoading={isFetchedMovieTMDB || isLoadingMovie}
							/>
							{movie && (
								<Popconfirm
									placement="bottom"
									title="Supprimer le film"
									description="Souhaitez-vous supprimer ce film ?"
									onConfirm={() => onDeleteMovie(movie.id)}
									okText="Oui, supprimer"
									cancelText="Annuler">
									<Button className="" block type="primary" danger size="large" htmlType="button">
										Supprimer le film
									</Button>
								</Popconfirm>
							)}
						</div>
					</div>
					<div className="col-span-3">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Ajouter une séance</h3>
						<div className="">
							<form noValidate autoComplete="off" onSubmit={handleSubmit}>
								<div className="flex flex-col gap-2">
									{!isLoadingMovie &&
										movie &&
										values.schedules &&
										values.schedules.length > 0 &&
										values.schedules.map((schedule, key) => (
											<MovieScheduleItem
												// eslint-disable-next-line react/no-array-index-key
												key={key}
												index={key}
												controls={
													<Popconfirm
														placement="left"
														title="Supprimer la séance"
														description="Souhaitez-vous éliminer la séance?"
														onConfirm={() => onDeleteScheduleItem(key, schedule.id as number)}
														okText="Oui, supprimer"
														cancelText="Annuler">
														<button className="text-red-700" type="button">
															<Trash2 size={17} />
														</button>
													</Popconfirm>
												}
												onChangeDateSchedule={(value, index) =>
													updateScheduleField('date', value.toISOString(), index)
												}
												onChangeTypeSchedule={(value, index) => {
													updateScheduleField('type', value, index);
												}}
												schedule={schedule}
											/>
										))}

									{isLoadingMovie && (
										<div className="h-22 grid grid-cols-5  gap-2 rounded-lg bg-white p-4">
											<div className="col-span-2 flex flex-col gap-2 ">
												<Skeleton height={18} width={120} />
												<Skeleton height={27} width="100%" />
											</div>
											<div className="col-span-3 flex flex-col gap-2">
												<Skeleton height={18} width={120} />
												<div className="flex gap-3">
													<Skeleton height={27} width={75} />
													<Skeleton height={27} width={45} />
													<Skeleton height={27} width={76} />
													<Skeleton height={27} width={60} />
													<Skeleton height={27} width={60} />
												</div>
											</div>
										</div>
									)}

									{values.schedules && values.schedules.length === 0 && (
										<div className="rounded-lg bg-white p-12">
											<Empty
												icon={<IconMovieCards />}
												title="No hay Schedules por el momento."
												description="Por favor agregar Schedules"
											/>
										</div>
									)}

									<AddItem height={88} onClick={() => addScheduleItem()}>
										<PlusCircle size={16} className="mr-1" /> Ajouter une séance
									</AddItem>
								</div>
								<div className=" my-2 flex justify-end gap-2">
									<Button type="primary" htmlType="submit" disabled={!(isValid && dirty)}>
										Editer le film
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default MovieEdit;
