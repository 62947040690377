import { QueryParametersSchedules } from '@pages/Planning/types';
import { scheduleService } from '@services/index';
import { useInfiniteQuery } from '@tanstack/react-query';

export default function useQuerySchedules({ startDate, endDate, page, limit }: QueryParametersSchedules) {
	return useInfiniteQuery({
		queryKey: ['Schedules', startDate, endDate],
		queryFn: ({ pageParam = page }) => scheduleService.getSchedules({ startDate, endDate, page: pageParam, limit }),
		getNextPageParam: (lastPage) => lastPage.meta.nextPage ?? undefined,
		initialPageParam: page,
		enabled: !!startDate && !!endDate
	});
}
