import { movieService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryMovieCreditsTMDB(movieTMDBId: number) {
	return useQuery({
		queryKey: ['MovieCreditsTMDB', movieTMDBId],
		queryFn: () => movieService.getMovieCreditsTMDB(movieTMDBId),
		enabled: false,
		refetchOnWindowFocus: false
	});
}
