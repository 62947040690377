import { MovieInventoryDto } from '@interfaces/inventoryInterface';
import { inventoryService } from '@services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationCreateInventory() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (inventoryCreateDto: MovieInventoryDto) => inventoryService.createInventoryItem(inventoryCreateDto),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['Inventory']
			});
		}
	});
}
