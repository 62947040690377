import { useQuery } from '@tanstack/react-query';

import { QueryParametersParticipationsUser } from '../../interfaces/scheduleInterface';
import { scheduleService } from '../../services';

export default function useQueryScheduleParticipationByUser({
	startDate,
	endDate,
	orderBy
}: QueryParametersParticipationsUser) {
	return useQuery({
		queryKey: ['Stats', startDate, endDate, orderBy],
		queryFn: () => scheduleService.getScheduleParticipationByUser({ startDate, endDate, orderBy }),
		enabled: !!startDate && !!endDate
	});
}
