import { IMovieScheduleDto } from '@interfaces/movieInterface';
import { Checkbox, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

interface IMovieScheduleItemProps {
	index: number;
	controls: React.ReactNode;
	onChangeDateSchedule: (value: Dayjs, index: number) => void;
	onChangeTypeSchedule: (value: string[], index: number) => void;
	schedule: IMovieScheduleDto;
}
const MovieScheduleItem = ({
	index,
	controls,
	onChangeDateSchedule,
	onChangeTypeSchedule,
	schedule
}: IMovieScheduleItemProps) => {
	const options = [
		{
			label: 'VF',
			value: 'Normal'
		},
		{
			label: 'VO',
			value: 'VO'
		},
		{
			label: 'VOSTF',
			value: 'VOSTF'
		},
		{
			label: 'SCO',
			value: 'SCO'
		},
		{
			label: '3D',
			value: '3D'
		}
	];

	return (
		<div className="relative grid gap-2 rounded-lg bg-white p-4 md:grid-cols-5">
			<div className="absolute right-2 top-1 gap-2">{controls && controls}</div>
			<div className="col-span-5 xl:col-span-2">
				<label className="block font-bold" htmlFor="">
					Date
				</label>
				<DatePicker
					value={schedule.date ? dayjs(schedule.date) : null}
					showNow={false}
					format="DD MMM YYYY, HH:mm"
					hideDisabledOptions
					showTime={{
						minuteStep: 5,
						defaultValue: dayjs('00:00', 'HH:mm'),
						format: 'HH:mm'
					}}
					onChange={(event) => onChangeDateSchedule(event as Dayjs, index)}
				/>
			</div>
			<div className="col-span-5 lg:col-span-3 xl:col-span-3">
				<label className="font-bold" htmlFor="">
					Type
				</label>
				<div className="my-2 md:my-0">
					<Checkbox.Group
						className="!grid grid-cols-2 gap-4 md:!flex"
						value={schedule.type || []}
						onChange={(optionsChecked) => onChangeTypeSchedule(optionsChecked as string[], index)}>
						{options.map((option) => (
							<Checkbox className="!me-0 !ms-0" key={option.value} value={option.value}>
								{option.label}
							</Checkbox>
						))}
					</Checkbox.Group>
				</div>
			</div>
		</div>
	);
};

export default MovieScheduleItem;
