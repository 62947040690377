import { useEffect, useState } from 'react';

import { Empty, MovieItem, Skeleton } from '@components/index';
import useMutationMoviesTMDB from '@hooks/movies/useMutationMoviesTMDB';
import IconEdit from '@icons/IconEdit';
import IconMovieCards from '@icons/IconMovieCards';
import { Inventory } from '@interfaces/inventoryInterface';
import { IMovie, IMovieTMDB } from '@interfaces/movieInterface';
import { Alert, Button, Input } from 'antd';
import dayjs from 'dayjs';
import { truncate } from 'lodash';
import { Link, useMatch } from 'react-router-dom';

import { MovieTMDBSearchType, MovieType } from './types';

type MovieTMDBSearchProps = {
	mode: MovieTMDBSearchType;
	type: MovieType;
	handlerMovieSelected: (movieTMDBSelected: IMovieTMDB) => void;
	selectMovieTMDB: IMovieTMDB;
	setSelectMovieTMDB: (movieTMDBSelected: IMovieTMDB) => void;
	title: string;
	year: string;
	setTitle: (title: string) => void;
	setYear: (year: string) => void;
	resultsToShow?: number;
	movieInDatabase?: IMovie | Inventory;
	isLoading?: boolean;
	buttonText?: string;
};

const MovieTMDBSearch = ({
	mode,
	type = MovieType.Movie,
	selectMovieTMDB,
	setSelectMovieTMDB,
	handlerMovieSelected,
	title,
	year,
	setTitle,
	setYear,
	resultsToShow = 10,
	movieInDatabase,
	isLoading = false,
	buttonText = 'Chercher le film'
}: MovieTMDBSearchProps) => {
	const matchUrl = useMatch('/inventory/edit/:inventoryId');

	const [isMovieTMDBSelected, setIsMovieTMDBSelected] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const { data: moviesTMDB, mutate: mutateMoviesTMDB } = useMutationMoviesTMDB();

	const handlerChangeMovie = () => {
		setIsMovieTMDBSelected(false);
	};

	const handlerUpdateMovieTMDB = () => {
		if (isMovieTMDBSelected) {
			setIsMovieTMDBSelected(false);
		}
		mutateMoviesTMDB({ title: title as string, year: Number(year) as number });
	};

	useEffect(() => {
		if (selectMovieTMDB) {
			setIsMovieTMDBSelected(true);
		}
	}, [selectMovieTMDB]);

	useEffect(() => {
		if (movieInDatabase && movieInDatabase.id && mode === MovieTMDBSearchType.Add) {
			setShowAlert(true);
		} else {
			setShowAlert(false);
		}
	}, [movieInDatabase]);

	return (
		<div className="flex flex-col gap-2">
			<div className="rounded-lg bg-white p-4">
				<form noValidate>
					<div className="flex flex-col gap-2 md:flex-row">
						<div className="md:w-col-60">
							<label className="font-bold" htmlFor="title">
								Titre du film
							</label>
							<Input id="title" value={title} onChange={(event) => setTitle(event.target.value)} />
						</div>
						<div className="md:w-col-40">
							<label className="font-bold" htmlFor="year">
								Année de sortie
							</label>
							<Input id="title" value={year} onChange={(event) => setYear(event.target.value)} />
						</div>
					</div>
					<div className="mt-4 flex items-end md:mt-2">
						<Button
							disabled={!(title && title.length > 2)}
							onClick={() => handlerUpdateMovieTMDB()}
							block
							type="primary"
							htmlType="button">
							{buttonText}
						</Button>
					</div>
				</form>
			</div>
			<div className="rounded-lg bg-white p-4">
				{!isMovieTMDBSelected && (
					<div>
						{moviesTMDB && moviesTMDB.results && (
							<div className="grid grid-cols-2 gap-3 md:grid-cols-3">
								{moviesTMDB.results.slice(0, resultsToShow).map((movieTMDBResult) => (
									<button
										type="button"
										key={movieTMDBResult.id}
										onClick={() => {
											setIsMovieTMDBSelected(true);
											setSelectMovieTMDB(movieTMDBResult);
											handlerMovieSelected(movieTMDBResult);
										}}>
										<div>
											<MovieItem
												height={200}
												posterPath={movieTMDBResult.poster_path}
												originalTitle={movieTMDBResult.title}
											/>
										</div>
									</button>
								))}
							</div>
						)}

						{moviesTMDB && moviesTMDB.results.length === 0 && title && title.length > 0 && (
							<div className="flex items-center justify-center">
								<Empty
									title="Aucun résultat"
									description={`Le film "${title}" sorti en "${year}" n'a pas été trouvé.`}
								/>
							</div>
						)}

						{!isLoading && !moviesTMDB && (
							<div className="flex items-center justify-center">
								<Empty
									icon={<IconMovieCards />}
									title="Pour trouver un film"
									description="Merci de renseigner le titre et l'année de sortie du film "
								/>
							</div>
						)}

						{isLoading && (
							<div className="flex flex-col gap-4 md:flex-row">
								<div className="w-col-50 self-center md:w-col-40">
									<div className="">
										<Skeleton height={290} width="100%" />
									</div>
								</div>

								<div className="text-center md:w-col-60 md:text-left">
									<div className="flex flex-col gap-2">
										<Skeleton height={20} width={200} />
										<Skeleton height={20} width={100} />
										<div className="flex flex-col gap-1 py-2">
											<Skeleton height={20} width="100%" />
											<Skeleton height={20} width="100%" />
											<Skeleton height={20} width="80%" />
											<Skeleton height={20} width="100%" />
											<Skeleton height={20} width="50%" />
											<Skeleton height={20} width="100%" />
											<Skeleton height={20} width="70%" />
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				)}

				{isMovieTMDBSelected && selectMovieTMDB && (
					<div>
						{showAlert && movieInDatabase && !matchUrl && (
							<div className="mb-2">
								<Alert
									message={
										<div>
											Attention, ce film existe déjà dans la base de données du planning.{' '}
											{MovieType.Inventory === type && (
												<Link to={`/inventory/edit/${movieInDatabase.id}`}>Editer ce film</Link>
											)}
											{MovieType.Movie === type && (
												<Link to={`/movies/edit/${movieInDatabase.id}`}>Editer ce film</Link>
											)}
										</div>
									}
									type="warning"
									showIcon
								/>
							</div>
						)}
						<div className="mb-2 flex items-center justify-between">
							<h4 className="text-lg font-bold">Film séléctionné</h4>
							<Button onClick={() => handlerChangeMovie()} size="small" className="flex items-center">
								<IconEdit height={16} width={16} className="mr-2" /> Changer de film
							</Button>
						</div>
						<div className="flex flex-col gap-4 md:flex-row">
							<div className="w-col-50 self-center md:w-col-40">
								<MovieItem
									height={250}
									posterPath={selectMovieTMDB.poster_path}
									originalTitle={selectMovieTMDB.original_title}
								/>
							</div>

							<div className="text-center md:w-col-60 md:text-left">
								<div className="text-lg font-bold">{selectMovieTMDB.original_title}</div>
								<div className="font-bold">{dayjs(selectMovieTMDB.release_date).format('DD/MM/YYYY')}</div>
								<p className="py-2">
									{truncate(selectMovieTMDB.overview, {
										length: 200
									})}
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default MovieTMDBSearch;
