import { ISchedule } from '@interfaces/scheduleInterface';
import {
	Suggestion,
	SuggestionCreateDto,
	SuggestionDetail,
	SuggestionUpdateDto
} from '@interfaces/suggestionInterface';

import axiosInstance from './interceptor';

export const createSuggestion = async (suggestionDto: SuggestionCreateDto): Promise<Suggestion> => {
	const suggestion = await axiosInstance.post<Suggestion>(
		`${import.meta.env.VITE_API_URL}/suggestion/`,
		suggestionDto
	);
	return suggestion.data;
};

export const getSuggestion = async (suggestionId: number): Promise<SuggestionDetail> => {
	const suggestion = await axiosInstance.get<SuggestionDetail>(
		`${import.meta.env.VITE_API_URL}/suggestion/${suggestionId}`
	);
	return suggestion.data;
};

export const updateDecision = async (suggestionUpdateDto: SuggestionUpdateDto): Promise<ISchedule> => {
	const suggestion = await axiosInstance.patch<ISchedule>(
		`${import.meta.env.VITE_API_URL}/suggestion/decision/`,
		suggestionUpdateDto
	);
	return suggestion.data;
};
