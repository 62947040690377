import { HTMLAttributes } from 'react';

interface IAddItemProps extends HTMLAttributes<HTMLDivElement> {
	height?: number;
}

const AddItem = ({ children, onClick, height = 112 }: IAddItemProps) => (
	<div
		aria-hidden="true"
		className="flex select-none items-center justify-center rounded-lg border-2 border-dashed border-slate-400 text-slate-500"
		onClick={onClick}
		role="button"
		style={{ height }}
		tabIndex={0}>
		{children}
	</div>
);

export default AddItem;
