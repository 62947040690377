import { Inventory, MovieInventoryDto, MovieInventoryUpdateDto, PosterFilter } from '@interfaces/inventoryInterface';
import { IPagination } from '@interfaces/paginationInterface';

import axiosInstance from './interceptor';

import { apiService } from '.';

export const getInventories = async (
	page?: number,
	pageSize?: number,
	search?: string,
	posterFilter?: PosterFilter,
	withPostersCount?: boolean
): Promise<IPagination<Inventory>> => {
	const params = apiService.getQueryParams([
		{ key: 'page', value: page },
		{ key: 'limit', value: pageSize },
		{ key: 'title', value: search },
		{ key: 'posterFilter', value: posterFilter },
		{ key: 'withPostersCount', value: withPostersCount }
	]);
	const inventories = await axiosInstance.get<IPagination<Inventory>>(`${import.meta.env.VITE_API_URL}/inventory`, {
		params
	});
	return inventories.data;
};

export const createInventoryItem = async (inventoryCreateDto: MovieInventoryDto): Promise<Inventory> => {
	const inventory = await axiosInstance.post<Inventory>(
		`${import.meta.env.VITE_API_URL}/inventory/`,
		inventoryCreateDto
	);
	return inventory.data;
};

export const updateInventoryItem = async (inventoryUpdateDto: MovieInventoryUpdateDto): Promise<Inventory> => {
	const inventory = await axiosInstance.patch<Inventory>(
		`${import.meta.env.VITE_API_URL}/inventory/`,
		inventoryUpdateDto
	);
	return inventory.data;
};

export const getInventoryByMovieTMDBId = async (movieTMDBId: number): Promise<Inventory> => {
	const inventory = await axiosInstance.get<Inventory>(
		`${import.meta.env.VITE_API_URL}/inventory/get-by/?movieTMDBId=${movieTMDBId}`
	);
	return inventory.data;
};

export const getInventory = async (inventoryId: number): Promise<Inventory> => {
	const inventory = await axiosInstance.get<Inventory>(`${import.meta.env.VITE_API_URL}/inventory/${inventoryId}/`);
	return inventory.data;
};

export const deleteInventory = async (inventoryId: number): Promise<Inventory> => {
	const inventory = await axiosInstance.patch<Inventory>(
		`${import.meta.env.VITE_API_URL}/inventory/delete/${inventoryId}/`
	);
	return inventory.data;
};
