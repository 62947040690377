import { INotification, NotificationQueryParameters } from '@interfaces/notificationInterface';
import { IPagination } from '@interfaces/paginationInterface';

import axiosInstance from './interceptor';

import { apiService } from '.';

export const getNotifications = async ({
	page,
	limit
}: NotificationQueryParameters): Promise<IPagination<INotification>> => {
	const params = apiService.getQueryParams([
		{ key: 'page', value: page },
		{ key: 'limit', value: limit }
	]);

	const notifications = await axiosInstance.get<IPagination<INotification>>(
		`${import.meta.env.VITE_API_URL}/notification/`,
		{ params }
	);
	return notifications.data;
};

export const readAllNotifications = async (): Promise<INotification[]> => {
	const notifications = await axiosInstance.get<INotification[]>(
		`${import.meta.env.VITE_API_URL}/notification/read/all`
	);
	return notifications.data;
};

export const readNotification = async (notificationId: number): Promise<INotification> => {
	const notifications = await axiosInstance.get<INotification>(
		`${import.meta.env.VITE_API_URL}/notification/read/${notificationId}`
	);
	return notifications.data;
};
