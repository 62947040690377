import { useEffect, useState } from 'react';

import { Skeleton } from '@components/index';
import { useQueryMovieCreditsTMDB, useQueryMovieTMDB } from '@hooks/index';
import { ISchedule } from '@interfaces/scheduleInterface';
import { durationMovie } from '@utils/durationMovie';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { truncate } from 'lodash';
import { ChevronDown, ChevronUp } from 'react-feather';

import { ICast, ICrew } from '../../interfaces/movieInterface';

type ScheduleCastProps = {
	open: boolean;
	setScheduleOpen: (scheduleId: number | undefined) => void;
	schedule: ISchedule;
};

const ScheduleCast = ({ open, setScheduleOpen, schedule }: ScheduleCastProps) => {
	const [staff, setStaff] = useState<(ICrew | ICast)[]>([]);

	const {
		data: credits,
		refetch: refetchCredits,
		isFetching: isLoadingCredits
	} = useQueryMovieCreditsTMDB(Number(schedule.movie.movieDBId) as number);

	const {
		data: movie,
		refetch: refetchMovie,
		isFetching: isLoadingMovie
	} = useQueryMovieTMDB({ movieTMDBId: Number(schedule.movie.movieDBId) as number });

	const handlerOnOpen = (movieTMDBId: number | undefined) => {
		if (movieTMDBId) {
			setScheduleOpen(schedule.id);
			refetchCredits();
			refetchMovie();
		} else {
			setScheduleOpen(undefined);
		}
	};

	useEffect(() => {
		if (credits) {
			const director = credits.crew.filter((crew) => crew.job === 'Director')[0];
			const cast = credits.cast.slice(0, 6);

			setStaff([]);
			setStaff([director, ...cast]);
		}
	}, [credits, setStaff]);

	return (
		<>
			{schedule.movie.movieDBId && open && (
				<div className="my-4 flex justify-center md:my-2 md:justify-start">
					<button
						type="button"
						className="text-md flex items-center justify-center gap-1 text-slate-500"
						onClick={() => handlerOnOpen(undefined)}>
						<ChevronUp size={18} />
						<div>Fermer les informations du film</div>
					</button>
				</div>
			)}
			{schedule.movie.movieDBId && !open && (
				<div className="my-4 flex justify-center md:my-2 md:justify-start">
					<button
						type="button"
						className="text-md flex items-center justify-center gap-1 text-slate-500"
						onClick={() => handlerOnOpen(schedule.movie.movieDBId as number)}>
						<ChevronDown size={18} />
						<div>Voir les informations du film</div>
					</button>
				</div>
			)}

			{open && movie && (
				<div className="mt-4 flex flex-col gap-6 md:flex-row">
					<div className="flex justify-center px-2 md:block md:w-col-20">
						{isLoadingMovie && <Skeleton height={333} width={222} />}

						{!isLoadingMovie && (
							<>
								{movie.poster_path && (
									<div
										className="rounded-lg bg-cover bg-center bg-no-repeat"
										style={{
											backgroundImage: `url(https://image.tmdb.org/t/p/w342${movie.poster_path})`,
											height: 333,
											width: 222
										}}
									/>
								)}
								{!movie.poster_path && (
									<div
										style={{ height: 333, width: 222 }}
										className="flex items-center justify-center rounded-lg bg-slate-200 px-2 text-center text-xl">
										Image non disponible
									</div>
								)}
							</>
						)}
					</div>
					<div className="flex flex-col gap-4 text-center md:w-col-80 md:text-left">
						<div className="flex flex-col gap-1">
							{isLoadingMovie && <Skeleton height={28} width={250} />}
							{!isLoadingMovie && (
								<h3 className="text-lg font-bold">
									{movie.title} ({dayjs(movie.release_date).format('YYYY')})
								</h3>
							)}

							{isLoadingMovie && <Skeleton height={24} width={150} />}
							{!isLoadingMovie && (
								<h2 className="text-base">
									<span className="font-bold">Durée:</span> {durationMovie(movie.runtime)}
								</h2>
							)}
						</div>

						<div className="text-gray-700">
							{isLoadingMovie && (
								<div className="flex flex-col gap-0.5">
									<Skeleton height={19} width="98%" />
									<Skeleton height={19} width="98%" />
									<Skeleton height={19} width="98%" />
									<Skeleton height={19} width="60%" />
								</div>
							)}

							{!isLoadingMovie && (
								<>
									{truncate(movie.overview, {
										length: 500
									})}
								</>
							)}
						</div>

						<div>
							{isLoadingCredits && (
								<div className=" grid grid-cols-2 gap-4 md:grid-cols-7">
									<Skeleton height={200} width={122} />
									<Skeleton height={200} width={122} />
									<Skeleton height={200} width={122} />
									<Skeleton height={200} width={122} />
									<Skeleton height={200} width={122} />
									<Skeleton height={200} width={122} />
									<Skeleton height={200} width={122} />
								</div>
							)}

							{!isLoadingCredits && staff && (
								<>
									<div className="hidden grid-cols-2 gap-4 md:grid md:grid-cols-7">
										<div className="font-bold md:col-span-1">Réalisateur</div>
										<div className="font-bold md:col-span-6">Acteurs</div>
									</div>
									<div className="grid grid-cols-2 gap-4 md:grid-cols-7">
										{staff.map((cast, key) => (
											// eslint-disable-next-line react/no-array-index-key
											<div key={`${cast.id}-${key}`}>
												{cast && (cast as ICrew).job && (
													<div className="text-sm font-bold md:hidden">
														{(cast as ICrew).job === 'Director' ? 'Réalisateur' : 'Acteur'}
													</div>
												)}
												<div className="rounded-lg border">
													{cast.profile_path && (
														<img
															src={`https://image.tmdb.org/t/p/w342/${cast.profile_path}`}
															alt=""
															className="rounded-t"
														/>
													)}

													{!cast.profile_path && (
														<div className="flex h-[200px] items-center justify-center rounded-t-lg bg-slate-200 px-2 text-center text-xl md:h-[181px]">
															Image non disponible
														</div>
													)}

													<div
														className={classNames(
															'flex h-12 items-center justify-center text-center text-sm font-bold'
														)}>
														{cast.name}
													</div>
												</div>
											</div>
										))}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ScheduleCast;
