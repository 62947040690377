import { useEffect, useState } from 'react';

import { CustomLink } from '@components/index';
import { NotificationItem } from '@elements/index';
import { useQueryNotifications, useQueryNotificationsReadAll } from '@hooks/index';
import { Badge, Popover, Spin } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { Bell } from 'react-feather';
import { Link } from 'react-router-dom';

dayjs.locale('fr');

const NotificationsList = () => {
	const [countNotificationsUnread, setCountNotificationsUnread] = useState<number>();

	const {
		data: notifications,
		refetch: refetchNotifications,
		isLoading: isLoadingNotifications
	} = useQueryNotifications({ page: 1, limit: 5 });

	const { refetch: refetchNotificationsReadAll } = useQueryNotificationsReadAll();

	const readAllNotificationsHandler = () => {
		refetchNotificationsReadAll();
		refetchNotifications();
	};

	useEffect(() => {
		if (notifications) {
			const userNotificationsUnreaded = notifications.meta.totalUnread;
			setCountNotificationsUnread(userNotificationsUnreaded);
		}
	}, [notifications]);

	return (
		<>
			<CustomLink className="block: md:hidden" to="/users/notifications">
				<Badge size="small" dot={!!(countNotificationsUnread && countNotificationsUnread > 0)}>
					<Bell className="cursor-pointer text-white" size={18} />
				</Badge>
			</CustomLink>
			<div className="hidden md:block">
				<Popover
					content={
						<div className="text-sm">
							<div className="flex justify-between border-b-2 border-gray-100 py-2">
								<div className="font-bold">Notifications</div>
								<button
									type="button"
									onClick={() => readAllNotificationsHandler()}
									className="text-xs text-blue-500">
									Marquer comme lues
								</button>
							</div>
							<div>
								{isLoadingNotifications && (
									<div className="flex h-60 items-center justify-center">
										<Spin />
									</div>
								)}
								{!isLoadingNotifications &&
									notifications &&
									notifications.items.map((notification) => (
										<div key={notification.id}>
											<NotificationItem
												notificationId={notification.id}
												title={notification.title}
												isRead={notification.read}
												type={notification.type}
												data={notification.data}
											/>
										</div>
									))}

								<div className="mt-4 flex justify-center">
									<Link to="/users/notifications" type="button" className="text-xs font-bold text-blue-500">
										Voir toutes les notifications
									</Link>
								</div>
							</div>
						</div>
					}
					title={null}
					trigger="click"
					overlayStyle={{ width: 500 }}>
					<Badge size="small" dot={!!(countNotificationsUnread && countNotificationsUnread > 0)}>
						<Bell className="cursor-pointer text-white" size={18} />
					</Badge>
				</Popover>
			</div>
		</>
	);
};

export default NotificationsList;
