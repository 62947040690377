import { useEffect, useState } from 'react';

import { Container, Header, MovieTMDBSearch, Subheader } from '@elements/index';
import { MovieTMDBSearchType, MovieType } from '@elements/Movie/types';
import { useMutationUpdateInventory, useQueryInventory, useQueryInventoryGetBy, useQueryMovieTMDB } from '@hooks/index';
import { Status } from '@interfaces/apiInterface';
import { MovieInventoryUpdateDto } from '@interfaces/inventoryInterface';
import { IMovieTMDB } from '@interfaces/movieInterface';
import { Button, Input, message } from 'antd';
import { useFormik } from 'formik';
import { Minus, Plus } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

const InventoryEdit = () => {
	const { inventoryId } = useParams();

	const navidate = useNavigate();

	const [title, setTitle] = useState('');
	const [year, setYear] = useState('');

	const [selectMovieTMDB, setSelectMovieTMDB] = useState<IMovieTMDB>();

	const { data: inventory } = useQueryInventory(Number(inventoryId) as number);
	const { data: movieInDatabase } = useQueryInventoryGetBy(selectMovieTMDB?.id as number);
	const { data: movieTMDBId } = useQueryMovieTMDB({
		movieTMDBId: inventory?.movieTMDBId as number,
		config: { enabled: !!inventory?.movieTMDBId }
	});
	const {
		mutate: mutateUpdateInventory,
		status: statusUpdateInventory,
		isPending: isLoadingUpdateInventory
	} = useMutationUpdateInventory();

	const onSubmitHandler = async (values: MovieInventoryUpdateDto) => {
		mutateUpdateInventory(values);
	};

	// Form
	const { values, setValues, handleSubmit, getFieldProps, touched, errors, isValid, dirty } =
		useFormik<MovieInventoryUpdateDto>({
			initialValues: {
				id: Number(inventoryId),
				title: '',
				poster_path: '',
				movieTMDBId: 0,
				posterCount: 0,
				posterSmallCount: 0,
				totalPosters: 0
			},
			onSubmit: onSubmitHandler,
			validationSchema: Yup.object({
				title: Yup.string().required('El titulo es requerido')
			})
		});

	const handlerMovieSelected = (movieTMDBSelected: IMovieTMDB): void => {
		setValues((prevValues) => ({
			...prevValues,
			title: title as string,
			poster_path: movieTMDBSelected.poster_path,
			movieTMDBId: movieTMDBSelected.id ? Number(movieTMDBSelected.id) : null
		}));
	};

	useEffect(() => {
		setSelectMovieTMDB(undefined);
	}, []);

	useEffect(() => {
		if (movieTMDBId && inventory && inventory.movieTMDBId === movieTMDBId.id) {
			setSelectMovieTMDB(movieTMDBId);
		} else {
			setSelectMovieTMDB(undefined);
		}
	}, [movieTMDBId]);

	useEffect(() => {
		if (inventory) {
			setTitle(inventory.title);
			setValues({
				id: inventory.id,
				title: inventory.title,
				poster_path: inventory.poster_path,
				movieTMDBId: inventory.movieTMDBId,
				posterCount: inventory.posterCount,
				posterSmallCount: inventory.posterSmallCount,
				totalPosters: inventory.totalPosters
			});
		}
	}, [inventory]);

	useEffect(() => {
		if (!title) return;

		setValues((prevValues) => ({
			...prevValues,
			title: title as string
		}));
	}, [title]);

	useEffect(() => {
		if (statusUpdateInventory === Status.Success) {
			message.success('Film correctement édité.');
			navidate('/inventory');
		}

		if (statusUpdateInventory === Status.Error) {
			message.warning('Une erreur est survenue, veuillez réessayer plus tard.');
		}
	}, [statusUpdateInventory]);

	return (
		<>
			<Header />
			<Container>
				<Subheader title="Modifier le film" />

				<div className="grid grid-cols-1 gap-2 md:grid md:grid-cols-5">
					<div className="col-span-2">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Information du film</h3>
						<MovieTMDBSearch
							movieInDatabase={movieInDatabase}
							mode={MovieTMDBSearchType.Edit}
							type={MovieType.Inventory}
							title={title as string}
							year={year as string}
							setTitle={setTitle}
							setYear={setYear}
							selectMovieTMDB={selectMovieTMDB as IMovieTMDB}
							setSelectMovieTMDB={setSelectMovieTMDB}
							handlerMovieSelected={handlerMovieSelected}
						/>
					</div>

					<div className="col-span-3">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Inventaire</h3>
						<form className="" noValidate autoComplete="off" onSubmit={handleSubmit}>
							<div className="flex flex-col justify-center gap-4 rounded-lg bg-white p-4 md:flex-row">
								<div>
									<label className="font-bold" htmlFor="posterCount">
										Affiches
									</label>
									<div className="flex w-full gap-1">
										<div>
											<Button
												size="large"
												type="primary"
												icon={<Minus size="16px" />}
												onClick={() => {
													if (values.posterCount > 0) {
														setValues((prevValues) => ({
															...prevValues,
															posterCount: values.posterCount - 1
														}));
													}
												}}
											/>
										</div>
										<Input
											size="large"
											type="number"
											className="w-full [appearance:textfield] md:!w-24 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
											status={touched.posterCount && errors.posterCount ? 'error' : undefined}
											id="posterCount"
											{...getFieldProps('posterCount')}
										/>
										<div>
											<Button
												size="large"
												type="primary"
												icon={<Plus size="16px" />}
												onClick={() => {
													setValues((prevValues) => ({
														...prevValues,
														posterCount: values.posterCount + 1
													}));
												}}
											/>
										</div>
									</div>
								</div>
								<div>
									<label className="font-bold" htmlFor="posterSmallCount">
										Affichettes
									</label>
									<div className="flex gap-1">
										<div>
											<Button
												size="large"
												type="primary"
												icon={<Minus size="16px" />}
												onClick={() => {
													if (values.posterSmallCount > 0) {
														setValues((prevValues) => ({
															...prevValues,
															posterSmallCount: values.posterSmallCount - 1
														}));
													}
												}}
											/>
										</div>
										<Input
											size="large"
											type="number"
											className="w-full [appearance:textfield] md:!w-24 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
											status={touched.posterSmallCount && errors.posterSmallCount ? 'error' : undefined}
											id="posterSmallCount"
											{...getFieldProps('posterSmallCount')}
										/>
										<div>
											<Button
												size="large"
												type="primary"
												icon={<Plus size="16px" />}
												onClick={() => {
													setValues((prevValues) => ({
														...prevValues,
														posterSmallCount: values.posterSmallCount + 1
													}));
												}}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className=" my-2 flex justify-end gap-2">
								<Button
									loading={isLoadingUpdateInventory}
									type="primary"
									htmlType="submit"
									disabled={!(isValid && dirty)}>
									Enregistrer
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Container>
		</>
	);
};

export default InventoryEdit;
