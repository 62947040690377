import { useEffect } from 'react';

import { CustomLink } from '@components/index';
import { NotificationsList } from '@elements/index';
import { useQueryUserProfile } from '@hooks/index';
import { usePermify, HasAccess } from '@permify/react-role';
import * as Sentry from '@sentry/browser';
import { authService } from '@services/index';
import { Dropdown } from 'antd';
import { ChevronDown } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
	const navigate = useNavigate();
	const { setUser: setUserRoles } = usePermify();

	const { data: userProfile } = useQueryUserProfile();
	const tokenLocal = authService.getLocalToken();

	const logoutHandler = () => {
		authService.logout();
		navigate('/login');
	};

	useEffect(() => {
		if (!tokenLocal) navigate('/login');
	}, [tokenLocal]);

	useEffect(() => {
		if (!userProfile) return;
		if (userProfile.passwordChangeed === 0) navigate('/users/change-password');
		setUserRoles({
			id: userProfile.id.toString(),
			roles: userProfile.roles.map((role) => role.name)
		});
		Sentry.setUser({
			email: userProfile.email,
			id: userProfile.id
		});
	}, [userProfile]);

	return (
		<div className="header flex bg-slate-800 text-slate-100">
			<div className="container mx-auto flex items-center justify-start py-5 text-center">
				<div className="flex w-full">
					<div className="hidden w-col-20 text-left text-xl font-bold lg:flex">
						<Link to="/">Cinéma Le Cep</Link>
					</div>
					<div className="flex w-full flex-col justify-between gap-4 lg:flex-row">
						<div className="flex flex-col justify-center gap-4 md:flex-row">
							<CustomLink activeClassName="font-bold !text-white" className=" text-slate-400 " to="/planning">
								Planning
							</CustomLink>
							<CustomLink activeClassName="font-bold !text-white" className=" text-slate-400" to="/vacations">
								Indisponibilités
							</CustomLink>
							<CustomLink activeClassName="font-bold !text-white" className=" text-slate-400" to="/my-vacations">
								Mes indisponibilités
							</CustomLink>
							<HasAccess roles={['admin']}>
								<CustomLink activeClassName="font-bold !text-white" className=" text-slate-400" to="/movies">
									Films
								</CustomLink>
							</HasAccess>
							<HasAccess roles={['admin']}>
								<Dropdown
									className="flex"
									trigger={['click']}
									menu={{
										items: [
											{
												key: '1',
												label: (
													<CustomLink className="font-bold text-slate-400" to="/users">
														Utilisateurs
													</CustomLink>
												)
											},
											{
												key: '2',
												label: (
													<CustomLink className="font-bold text-slate-400" to="/users/stats">
														Rapport
													</CustomLink>
												)
											}
										]
									}}>
									<button type="button" onClick={(e) => e.preventDefault()}>
										<div className="flex h-auto items-center gap-[2px] font-normal text-slate-400">
											Bénévoles <ChevronDown size={16} className="self-center" />
										</div>
									</button>
								</Dropdown>
							</HasAccess>
							<HasAccess roles={['inventory']}>
								<CustomLink activeClassName="font-bold !text-white" className=" text-slate-400" to="/inventory">
									Affiches
								</CustomLink>
							</HasAccess>
						</div>
						<div className="flex items-center justify-center gap-5">
							<div className="items-center gap-3">
								<div className="flex justify-center">
									<NotificationsList />
								</div>
							</div>
							{userProfile && <div className="hidden items-center xl:block">{userProfile.email}</div>}
							<button type="button" onClick={() => logoutHandler()} className="">
								Déconnexion
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
