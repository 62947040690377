import { useEffect, useState } from 'react';

import { AddItem } from '@components/index';
import { Container, Header, MovieTMDBSearch, Subheader } from '@elements/index';
import { MovieTMDBSearchType, MovieType } from '@elements/Movie/types';
import { useMutationMovie, useQueryMoviesGetBy } from '@hooks/index';
import { Status } from '@interfaces/apiInterface';
import { IMovieTMDB, IMovieScheduleDto, MovieDto } from '@interfaces/movieInterface';
import { Button, message } from 'antd';
import { useFormik } from 'formik';
import { PlusCircle, Trash2 } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import MovieScheduleItem from './MovieScheduleItem';

type FieldName = keyof IMovieScheduleDto;

const MoviesAdd = () => {
	const navigate = useNavigate();

	const [selectMovieTMDB, setSelectMovieTMDB] = useState<IMovieTMDB>();
	const [title, setTitle] = useState<string>();
	const [year, setYear] = useState<string>();

	const { mutate: mutateMovie, status: mutationMovieStatus } = useMutationMovie();
	const { data: movieInDatabase, refetch: refetchMovieInDatabase } = useQueryMoviesGetBy(
		selectMovieTMDB?.id as number
	);

	const onSubmitHandler = async (values: MovieDto) => {
		mutateMovie({ movieDto: values });
	};

	// Form
	const { values, setValues, isValid, dirty, resetForm, handleSubmit } = useFormik<MovieDto>({
		initialValues: {
			title: '',
			poster_path: '',
			movieDBId: null,
			schedules: [{ date: '', type: [] }]
		},
		onSubmit: onSubmitHandler,
		validationSchema: Yup.object({
			title: Yup.string().required('El titulo es requerido'),
			schedules: Yup.array().of(
				Yup.object({
					date: Yup.string().required('La fecha y hora es requerida'),
					type: Yup.array().min(1, 'Minimo un tipo debe ser seleccionado')
				})
			)
		})
	});

	const handlerMovieSelected = (movieTMDBSelected: IMovieTMDB): void => {
		setValues((prevValues) => ({
			...prevValues,
			title: title as string,
			poster_path: movieTMDBSelected.poster_path,
			movieDBId: movieTMDBSelected.id ? Number(movieTMDBSelected.id) : null
		}));
	};

	const updateScheduleField = <T extends FieldName>(
		fieldName: T,
		value: IMovieScheduleDto[T],
		index: number
	): void => {
		setValues((prevValues) => {
			const { schedules } = prevValues;
			const newSchedules = [...schedules];
			const item = { ...newSchedules[index] };
			item[fieldName] = value;
			newSchedules[index] = item;
			return { ...prevValues, schedules: newSchedules };
		});
	};

	const addScheduleItem = () => {
		setValues((prevValues) => ({
			...prevValues,
			schedules: [...prevValues.schedules, { date: '', type: [] }]
		}));
	};

	const onDeleteScheduleItem = (index: number) => {
		setValues((prevValues) => {
			const { schedules } = prevValues;
			const newSchedules = schedules.filter((_, i) => i !== index);
			return { ...prevValues, schedules: newSchedules };
		});
	};

	const onClearForm = () => {
		resetForm();
		setSelectMovieTMDB(undefined);
	};

	useEffect(() => {
		if (mutationMovieStatus === Status.Error) {
			message.error('Une erreur est survenue, veuillez réessayer plus tard.');
		}
		if (mutationMovieStatus === Status.Success) {
			message.success('Le film a été correctement créé.');
			navigate('/movies');
		}
	}, [mutationMovieStatus]);

	useEffect(() => {
		if (!title) return;

		setValues((prevValues) => ({
			...prevValues,
			title: title as string
		}));
	}, [title]);

	useEffect(() => {
		refetchMovieInDatabase();
	}, [selectMovieTMDB]);

	return (
		<>
			<Header />
			<Container>
				<Subheader title="Ajouter un film" />

				<div className="flex flex-col gap-2 md:grid md:grid-cols-5">
					<div className="col-span-2">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Informations du film</h3>
						<MovieTMDBSearch
							type={MovieType.Movie}
							mode={MovieTMDBSearchType.Add}
							movieInDatabase={movieInDatabase}
							title={title as string}
							year={year as string}
							setTitle={setTitle}
							setYear={setYear}
							selectMovieTMDB={selectMovieTMDB as IMovieTMDB}
							setSelectMovieTMDB={setSelectMovieTMDB}
							handlerMovieSelected={handlerMovieSelected}
						/>
					</div>
					<div className="col-span-3">
						<h3 className="mb-2 text-lg font-bold text-slate-600">Séances</h3>
						<div className="">
							<form noValidate autoComplete="off" onSubmit={handleSubmit}>
								<div className="flex flex-col gap-2">
									{values.schedules.map((schedule, key) => (
										<MovieScheduleItem
											// eslint-disable-next-line react/no-array-index-key
											key={key}
											index={key}
											controls={
												<button
													aria-label="delete item"
													type="button"
													className=" text-red-700"
													onClick={() => onDeleteScheduleItem(key)}>
													<Trash2 size={17} />
												</button>
											}
											onChangeDateSchedule={(value, index) =>
												updateScheduleField('date', value.toISOString(), index)
											}
											onChangeTypeSchedule={(value, index) => updateScheduleField('type', value, index)}
											schedule={schedule}
										/>
									))}

									<AddItem height={88} onClick={() => addScheduleItem()}>
										<PlusCircle size={16} className="mr-1" /> Ajouter une séance
									</AddItem>
								</div>
								<div className=" my-2 flex justify-end gap-2">
									<Button type="default" htmlType="button" onClick={() => onClearForm()}>
										Annuler
									</Button>
									<Button type="primary" htmlType="submit" disabled={!(isValid && dirty)}>
										Enregistrer
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default MoviesAdd;
