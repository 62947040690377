import { IPagination } from '@interfaces/paginationInterface';
import { ChangePasswordDto, IUser, QueryParametersUsers, UserDto } from '@interfaces/userInterface';

import axiosInstance from './interceptor';

import { apiService } from '.';

export const getUsers = async ({
	page,
	perPage,
	fullName,
	role
}: QueryParametersUsers): Promise<IPagination<IUser>> => {
	const params = apiService.getQueryParams([
		{ key: 'page', value: page },
		{ key: 'perPage', value: perPage },
		{ key: 'fullName', value: fullName },
		{ key: 'role', value: role }
	]);
	const users = await axiosInstance.get<IPagination<IUser>>(`${import.meta.env.VITE_API_URL}/users/`, { params });
	return users.data;
};

export const getUser = async (userId: number): Promise<IUser> => {
	const user = await axiosInstance.get<IUser>(`${import.meta.env.VITE_API_URL}/users/${userId}`, {});
	return user.data;
};

export const createUser = async (userDto: UserDto): Promise<IUser> => {
	const user = await axiosInstance.post<IUser>(`${import.meta.env.VITE_API_URL}/users/`, userDto);
	return user.data;
};

export const updateUser = async (userId: number, userDto: UserDto): Promise<IUser> => {
	const user = await axiosInstance.patch<IUser>(`${import.meta.env.VITE_API_URL}/users/${userId}`, userDto);
	return user.data;
};

export const deleteUser = async (userId: number): Promise<IUser> => {
	const user = await axiosInstance.delete<IUser>(`${import.meta.env.VITE_API_URL}/users/${userId}`);
	return user.data;
};

export const changePasswordUser = async (changePasswordDto: ChangePasswordDto): Promise<IUser> => {
	const user = await axiosInstance.post<IUser>(`${import.meta.env.VITE_API_URL}/users/change-password`, {
		changePasswordDto
	});
	return user.data;
};

export const resetPasswordByDefaultUser = async (userId: number): Promise<IUser> => {
	const user = await axiosInstance.post<IUser>(`${import.meta.env.VITE_API_URL}/users/reset/${userId}`);
	return user.data;
};
