import { IIcon } from './types';

const IconPlusCircle = ({ height = 24, width = 24, className }: IIcon) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		className={className || ''}
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		strokeWidth="2">
		<circle cx="12" cy="12" r="10" />
		<path d="M12 8v8M8 12h8" />
	</svg>
);

export default IconPlusCircle;
