import { authService } from '@services/index';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
	const token = authService.getLocalToken();

	return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
