type MoviePosterProps = {
	posterPath: string;
	height: string | number;
	width: string | number;
};

const MoviePoster = ({ posterPath, height, width }: MoviePosterProps) => (
	<div>
		{posterPath && (
			<div
				className="rounded-t-lg bg-cover bg-center bg-no-repeat"
				style={{
					backgroundImage: `url(https://image.tmdb.org/t/p/w342${posterPath})`,
					height,
					width
				}}
			/>
		)}
		{!posterPath && (
			<div
				style={{ height, width }}
				className="flex items-center justify-center rounded-t-lg bg-slate-200 px-2 text-center text-xl">
				Image non disponible
			</div>
		)}
	</div>
);

export default MoviePoster;
