import { Empty, Skeleton } from '@components/index';
import { Container, Header, Subheader, VacationItem } from '@elements/index';
import { useQueryVacations } from '@hooks/index';

const Vacations = () => {
	const { data: vacations, isLoading: isLoadingVacations } = useQueryVacations();

	return (
		<>
			<Header />
			<Container>
				<Subheader title="Toutes les indisponibilités" />

				<div className="flex flex-col gap-2">
					<div className="hidden grid-cols-4 items-center gap-4 rounded-lg  px-4 py-1 font-bold text-slate-500 md:grid">
						<div>Bénévole</div>
						<div>Rôle(s)</div>
						<div>Début de l'indisponibilité</div>
						<div>Fin de l'indisponibilité</div>
					</div>
					{vacations &&
						vacations.map((vacation) => (
							<div key={vacation.id}>
								<VacationItem
									userFullName={vacation.user.fullName}
									roles={vacation.user.roles}
									startDate={vacation.startDate}
									endDate={vacation.endDate}
								/>
							</div>
						))}

					{vacations && vacations.length === 0 && (
						<div className="rounded-lg bg-white p-12">
							<Empty
								title="No hay Vacations por el momento."
								description="Cuando otros usuarios agregen sus vacaciones apareceran en esta lista."
							/>
						</div>
					)}
				</div>

				{isLoadingVacations && (
					<div className="flex flex-col gap-4 py-1">
						<Skeleton height={57} width="100%" />
						<Skeleton height={57} width="100%" />
						<Skeleton height={57} width="100%" />
						<Skeleton height={57} width="100%" />
					</div>
				)}
			</Container>
		</>
	);
};

export default Vacations;
