export interface IRole {
	id: number;
	name: string;
}

export enum UserStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive'
}

export enum Role {
	Responsable = 'responsable',
	Cashier = 'cashier',
	Controller = 'controller',
	Store = 'store',
	Operator = 'operator',
	Glasses = 'glasses'
}

export interface IUser {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
	status: string;
	phone: string;
	movil: string;
	address: null | string;
	zip: null | string;
	city: null | string;
	email: string;
	passwordChangeed: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: null;
	roles: IRole[];
}

export type UserDto = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	movil: string;
	roles: number[];
};

export type QueryParametersUsers = {
	page?: number;
	perPage?: number;
	fullName?: string;
	role?: string;
};

export type FilterRoleUsers = {
	value: string;
	label: string;
};

export type ChangePasswordDto = {
	password: string;
	passwordConfirmation: string;
};

export type ScheduleParticipacionUser = {
	id: number;
	firstName: string;
	lastName: string;
	fullName: string;
	responsable: number;
	cashier: number;
	controller: number;
	store: number;
	operator: number;
	glasses: number;
	total: number;
};
