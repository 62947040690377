import { ISchedule } from './scheduleInterface';
import { IUser } from './userInterface';

export enum SuggestionsStatus {
	Pending = 'pending',
	Taken = 'taken'
}

export enum SuggestionDecision {
	Accepted = 'accepted',
	Rejected = 'rejected'
}

export type SuggestionUpdateDto = {
	id: number;
	scheduleId: number;
	userToSuggest: number;
	userSuggester: number;
	role: string;
	decision: SuggestionDecision;
};

export type SuggestionCreateDto = Omit<SuggestionUpdateDto, 'id' | 'decision'>;

export type Suggestion = {
	schedule: ISchedule;
	userToSuggest: number;
	userSuggester: number;
	role: string;
	status: string;
	id: number;
};

export type SuggestionDetail = {
	schedule: ISchedule;
	userToSuggest: IUser;
	userSuggester: IUser;
	role: string;
	status: string;
	id: number;
};
