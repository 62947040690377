import { IAuthDto, ILoginResponse, IUserProfile, LocalToken } from '@interfaces/authInterface';
import { axiosInstance } from '@services/index';

// Local storage
export const setLocalToken = (localToken: string): void => {
	localStorage.removeItem(LocalToken.TOKEN);
	localStorage.setItem(LocalToken.TOKEN, localToken);
};

export const getLocalToken = (): string => localStorage.getItem(LocalToken.TOKEN) || '';

export const deleteLocalToken = (): void => {
	localStorage.removeItem(LocalToken.TOKEN);
};

export const login = async (authDto: IAuthDto): Promise<ILoginResponse> => {
	const auth = await axiosInstance.post<ILoginResponse>(`${import.meta.env.VITE_API_URL}/auth/login/`, authDto);
	setLocalToken(auth.data.access_token);
	return auth.data;
};

export const logout = (): void => {
	deleteLocalToken();
};

export const getUserProfile = async (): Promise<IUserProfile> => {
	const user = await axiosInstance.get<IUserProfile>(`${import.meta.env.VITE_API_URL}/auth/user/`);
	return user.data;
};
