import { HttpMessage } from '@interfaces/apiInterface';
import { IPagination } from '@interfaces/paginationInterface';
import { ISchedule, IScheduleDto, QueryParametersParticipationsUser } from '@interfaces/scheduleInterface';
import { QueryParametersSchedules } from '@pages/Planning/types';
import qs from 'qs';

import { ScheduleParticipacionUser } from '../interfaces/userInterface';

import axiosInstance from './interceptor';

import { apiService } from '.';

export const getSchedules = async ({
	startDate,
	endDate,
	page,
	limit
}: QueryParametersSchedules): Promise<IPagination<ISchedule>> => {
	const params = apiService.getQueryParams([
		{ key: 'startDate', value: startDate },
		{ key: 'endDate', value: endDate },
		{ key: 'page', value: page },
		{ key: 'limit', value: limit }
	]);
	const schedules = await axiosInstance.get<IPagination<ISchedule>>(`${import.meta.env.VITE_API_URL}/schedule/`, {
		params
	});
	return schedules.data;
};

export const updateSchedule = async (scheduleId: number, scheduleDto: IScheduleDto): Promise<ISchedule> => {
	const schedule = await axiosInstance.patch<ISchedule>(
		`${import.meta.env.VITE_API_URL}/schedule/update/${scheduleId}/`,
		scheduleDto
	);
	return schedule.data;
};

export const deleteSchedule = async (scheduleId: number): Promise<HttpMessage> => {
	const schedule = await axiosInstance.delete<HttpMessage>(
		`${import.meta.env.VITE_API_URL}/schedule/delete/${scheduleId}`
	);
	return schedule.data;
};

export const getScheduleParticipationByUser = async ({
	startDate,
	endDate,
	orderBy
}: QueryParametersParticipationsUser): Promise<ScheduleParticipacionUser[]> => {
	const params = qs.stringify(
		{
			startDate,
			endDate,
			orderBy
		},
		{ arrayFormat: 'brackets' }
	);

	const stats = await axiosInstance.get<ScheduleParticipacionUser[]>(
		`${import.meta.env.VITE_API_URL}/schedule/report/participations/?${params}`
	);
	return stats.data;
};

export const getScheduleParticipationCSV = async ({
	startDate,
	endDate,
	orderBy
}: QueryParametersParticipationsUser): Promise<Blob> => {
	const params = qs.stringify(
		{
			startDate,
			endDate,
			orderBy
		},
		{ arrayFormat: 'brackets' }
	);

	const response = await axiosInstance.get<Blob>(
		`${import.meta.env.VITE_API_URL}/schedule/report/participations/download?${params}`,
		{ responseType: 'blob' }
	);
	return response.data;
};
