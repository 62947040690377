interface IParameterDto {
	key: string;
	value: string | number | undefined | boolean;
}

export interface IParameters {
	[param: string]: number | number[] | string | string[];
}

export const PAGE_SIZE = 15;

export const getQueryParams = (params: IParameterDto[]) => {
	const object: IParameters = {};
	params.forEach((param: IParameterDto) => {
		if ((param.value && param.key) || param.value === 0) {
			if (typeof param.value === 'boolean') {
				object[param.key] = param.value ? 'true' : 'false';
			} else {
				object[param.key] = param.value;
			}
		}
	});
	return object;
};
