import { useState } from 'react';

import { MoviePoster } from '@components/index';
import { Image } from 'antd';
import classNames from 'classnames';
import { truncate } from 'lodash';

type MovieInventoryPosterProps = {
	posterCount: number;
	posterSmallCount: number;
	posterPath: string;
	title: string;
	height: number | string;
	width: number | string;
};

const MovieInventoryPoster = ({
	posterPath,
	title,
	height,
	width,
	posterCount,
	posterSmallCount
}: MovieInventoryPosterProps) => {
	const [showFullImage, setShowFullImage] = useState(false);
	return (
		<>
			<button className="w-full" type="button" onClick={() => setShowFullImage(true)}>
				<div className={classNames('relative rounded-lg bg-white shadow')}>
					<div className="shadow-lg">
						<MoviePoster posterPath={posterPath} height={height} width={width} />
					</div>
					<div className="flex flex-col gap-2 p-2 md:gap-4 md:p-4">
						<div className="flex items-center justify-center">
							<div className="flex h-12 items-center text-center font-bold">
								{title &&
									truncate(title, {
										length: 35,
										separator: ' '
									})}
							</div>
						</div>
						<div className="ustify-between flex gap-1 md:gap-4 ">
							<div className="flex w-[80px] flex-col items-center justify-center rounded-lg bg-slate-200 p-2">
								<div className="text-center text-2xl font-bold">{posterCount}</div>
								<small>{posterCount <= 1 ? 'Affiche' : 'Affiches'}</small>
							</div>
							<div className="flex w-[80px] flex-col items-center justify-center rounded-lg bg-slate-200 p-2">
								<div className="text-center text-2xl font-bold">{posterSmallCount}</div>
								<small>{posterSmallCount <= 1 ? 'Affichette' : 'Affichettes'}</small>
							</div>
						</div>
					</div>
				</div>
			</button>
			<Image
				height={0}
				width={0}
				preview={{
					movable: false,
					toolbarRender: () => null,
					visible: showFullImage,
					src: `https://image.tmdb.org/t/p/w780${posterPath}`,
					onVisibleChange: (value) => {
						setShowFullImage(value);
					}
				}}
				style={{ display: 'none' }}
			/>
		</>
	);
};

export default MovieInventoryPoster;
