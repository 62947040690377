import { DATE_DAYJS_FORMAT } from '@enums/dates';
import { IRole } from '@interfaces/userInterface';
import { generateRoleLabel } from '@utils/generateRoleLabel';
import { Tag } from 'antd';
import dayjs from 'dayjs';

type VacationItemProps = {
	userFullName: string;
	roles: IRole[];
	startDate: string;
	endDate: string;
};

const VacationItem = ({ userFullName, roles, startDate, endDate }: VacationItemProps) => (
	<div className="grid grid-cols-1 items-center gap-4 rounded-lg bg-white p-4 md:grid-cols-4">
		<div>
			<div className="text-xs uppercase tracking-wide text-slate-500 md:hidden">Bénévole</div>
			<div className="font-bold">{userFullName}</div>
		</div>
		<div>
			<div className="text-xs uppercase tracking-wide text-slate-500 md:hidden">Rôles</div>
			<div>
				{roles.map((role) => (
					<Tag key={role.id} color={generateRoleLabel(role.name).color}>
						{generateRoleLabel(role.name).label}
					</Tag>
				))}
			</div>
		</div>
		<div>
			<div className="text-xs uppercase tracking-wide text-slate-500 md:hidden">Début de l'indisponibilité</div>
			<div>{dayjs(startDate).format(DATE_DAYJS_FORMAT.FR_SEMI_LONG)}</div>
		</div>
		<div>
			<div className="text-xs uppercase tracking-wide text-slate-500 md:hidden">Fin de l'indisponibilité</div>
			<div>{dayjs(endDate).format(DATE_DAYJS_FORMAT.FR_SEMI_LONG)}</div>
		</div>
	</div>
);

export default VacationItem;
