import { userService } from '@services/index';
import { useQuery } from '@tanstack/react-query';

export default function useQueryUsers(userId: number) {
	return useQuery({
		queryKey: ['User', userId],
		queryFn: () => userService.getUser(userId),
		enabled: !!userId,
		refetchOnWindowFocus: false
	});
}
