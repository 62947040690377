import { IUser } from '@interfaces/userInterface';
import { generateRoleLabel } from '@utils/generateRoleLabel';
import { Popconfirm, Tag } from 'antd';
import { Edit3, Trash2 } from 'react-feather';

export type CardUserProps = {
	user: IUser;
	onDelete: (userId: number) => void;
	onUpdate: (userId: number) => void;
};

const CardUser = ({ user, onDelete, onUpdate }: CardUserProps) => {
	const { firstName, lastName, email, phone, movil, roles } = user;

	return (
		<div className="relative flex flex-col gap-2 rounded bg-white px-4 py-6 shadow">
			<div>
				<div className="xs:block flex justify-between">
					<div className="text-lg font-bold">
						{firstName} {lastName}
					</div>
				</div>
				<div className="text-sm text-gray-500">
					<a href={`mailto:${email}`}>{email}</a>
				</div>
			</div>
			<div className="text-sm">
				<div className="mb-1">
					<a href="tel:02 40 43 84 07">
						<strong>Fixe:</strong> {phone}
					</a>
				</div>
				<div className="">
					<a href="tel:06 20 30 04 40">
						<strong>Portable:</strong> {movil}
					</a>
				</div>
			</div>
			<div className="flex flex-wrap gap-2 text-center">
				{roles.map((role) => (
					<Tag key={role.id} color={generateRoleLabel(role.name).color}>
						{generateRoleLabel(role.name).label}
					</Tag>
				))}
			</div>
			<div className="absolute right-2 top-2 flex">
				<div className="flex gap-2">
					<button type="button" onClick={() => onUpdate(user.id)} aria-label="Editer l'utilisateur">
						<Edit3 className="text-gray-500" size={16} />
					</button>

					<Popconfirm
						title="Supprimer le bénévole"
						description="Confirmez-vous la suppression?"
						onConfirm={() => onDelete(user.id)}
						okText="Confirmer"
						cancelText="Annuler">
						<button type="button" aria-label="Supprimer l'utilisateur">
							<Trash2 className="text-red-500" size={16} />
						</button>
					</Popconfirm>
				</div>
			</div>
		</div>
	);
};

export default CardUser;
