import { ChangePasswordDto } from '@interfaces/userInterface';
import { userService } from '@services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useMutationResetPassword() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (changePasswordDto: ChangePasswordDto) => userService.changePasswordUser(changePasswordDto),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['Users'] });
		}
	});
}
