import { useEffect, useState } from 'react';

import { Empty, MovieItem, Search, Skeleton } from '@components/index';
import { Container, Header, Subheader } from '@elements/index';
import { useQueryMovies } from '@hooks/index';
import IconMovieCards from '@icons/IconMovieCards';
import IconPlusCircle from '@icons/IconPlusCircle';
import { useMediaQuery } from '@uidotdev/usehooks';
import { Button, Pagination } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';

const Movies = () => {
	const navigate = useNavigate();
	const isSmDevice = useMediaQuery('only screen and (max-width : 768px)');

	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(40);
	const [search, setSearch] = useState<string>('');

	const {
		data: movies,
		refetch: refetchMovies,
		isLoading: isLoadingMovies
	} = useQueryMovies({ page, pageSize, search });

	const goToAddMovie = () => {
		navigate('/movies/add');
	};

	const onSearch = (valueToSearch: string) => {
		setSearch(valueToSearch);
		setPage(1);
	};

	const onHandlerSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setSearch((event.target as HTMLInputElement).value);
			setPage(1);
		}
	};

	useEffect(() => {
		refetchMovies();
	}, [search, page, pageSize]);

	return (
		<>
			<Header />
			<Container>
				<Subheader
					title="Films"
					controls={
						<Button type="primary" htmlType="button" onClick={() => goToAddMovie()}>
							<IconPlusCircle height={16} width={16} className="mr-1" /> Ajouter un film
						</Button>
					}
				/>

				<div className="my-4">
					<Search
						onKeyDown={(event) => onHandlerSearchKeyDown(event)}
						placeholder="Rechercher un film"
						onSearch={(value) => onSearch(value)}
						onClear={() => setSearch('')}
						value={search}
					/>
				</div>

				{movies && movies.items && movies.items.length > 0 && (
					<div className="grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8">
						{movies.items.map((movie) => (
							<NavLink to={`/movies/edit/${movie.id}`} key={movie.id}>
								<MovieItem
									key={movie.id}
									originalTitle={movie.original_title ? movie.original_title : movie.title}
									posterPath={movie.poster_path}
									height={isSmDevice ? 300 : undefined}
								/>
							</NavLink>
						))}
					</div>
				)}

				{isLoadingMovies && (
					<div className="grid grid-cols-2 gap-3 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8">
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
						<Skeleton height={257} width="100%" />
					</div>
				)}

				{movies && movies.items && movies.items.length === 0 && (
					<div className="my-6 flex flex-col items-center justify-center gap-4">
						<Empty
							icon={<IconMovieCards />}
							title="Aucun film trouvé"
							description={`Nous n'avons pas trouvé le film "${search}"`}
						/>
						<Button type="primary" onClick={() => setSearch('')}>
							Réinitialiser la recherche
						</Button>
					</div>
				)}

				{movies && movies.items && movies.meta.totalItems > pageSize && (
					<div className="my-6 flex justify-end">
						<Pagination
							current={page}
							total={movies.meta.totalItems}
							pageSize={pageSize}
							onChange={(pageChanged, pageSizeChanged) => {
								setPage(pageChanged);
								setPageSize(pageSizeChanged);
							}}
						/>
					</div>
				)}
			</Container>
		</>
	);
};

export default Movies;
