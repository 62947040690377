import { useState } from 'react';

import { Empty, Search, Skeleton } from '@components/index';
import { Container, MovieInventoryPoster } from '@elements/index';
import { useQueryInventories } from '@hooks/index';
import IconMovieCards from '@icons/IconMovieCards';
import { PosterFilter } from '@interfaces/inventoryInterface';
import { Button, Pagination } from 'antd';

const Posters = () => {
	const [search, setSearch] = useState<string>('');
	const [page, setPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(20);

	const { data: inventories, isLoading: isLoadingInventories } = useQueryInventories({
		page,
		pageSize,
		search,
		posterFilter: PosterFilter.NOT_EMPTY,
		withPostersCount: true
	});

	const onSearch = (valueToSearch: string) => {
		setSearch(valueToSearch);
		setPage(1);
	};

	const onHandlerSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			setSearch((event.target as HTMLInputElement).value);
			setPage(1);
		}
	};

	return (
		<>
			<header className="header flex bg-slate-800 text-slate-100">
				<div className="container mx-auto items-center justify-start py-5 text-center md:flex">
					<div className="text-xl font-bold md:w-col-20 md:text-left ">Cinéma Le Cep</div>
				</div>
			</header>

			<Container>
				<div className="flex flex-col gap-10 py-6">
					<div className="flex justify-center">
						<div className="flex flex-col gap-2 text-center">
							<h1 className="text-5xl font-bold">Catalogue d’affiches de films</h1>
							<h2 className="text-3xl font-bold">Cinéma Le Cep à Vallet</h2>
						</div>
					</div>

					<div className="flex justify-center rounded-xl bg-white p-6">
						<div className="flex flex-col items-center justify-center text-center">
							<div className="mb-2">
								Nous vous proposons à la vente des affiches <strong>(120x160cm)</strong> et des affichettes{' '}
								<strong>(40x60cm)</strong>.
							</div>

							<div className="mb-4 flex flex-col gap-1">
								<div>
									<strong>7€</strong> pour une affiche
								</div>
								<div>
									<strong>5€</strong> pour une affichette
								</div>
							</div>

							<div className="mb-4 font-bold">
								Vous pouvez consulter notre stock par l’outil de recherche ci-dessous.
							</div>

							<div className="">
								Si vous souhaitez réaliser un achat, merci d’envoyer un mail à{' '}
								<a className="font-bold text-blue-500 underline" href="mailto:animation.cinemalecep@orange.fr">
									animation.cinemalecep@orange.fr
								</a>
								.
							</div>

							<div className="mb-4">
								Le cinéma vous avertira par mail de la mise à disposition de votre commande.
							</div>

							<div className="mb-6">
								Pour récupérer votre commande et procéder au règlement, merci de vous rendre au:
							</div>

							<div className="mb-6">
								<div className="font-bold">Cinéma Le Cep, 8 boulevard E. Dejoie – 44330 Vallet</div>
								<div>Le mercredi de 9h à 12h ou en caisse aux horaires des séances programmées.</div>
							</div>

							<div>Le cinéma accepte les paiements en espèces ou par chèque.</div>
						</div>
					</div>

					<Search
						onKeyDown={(event) => onHandlerSearchKeyDown(event)}
						placeholder="Rechercher un film"
						onSearch={(value) => onSearch(value)}
						onClear={() => setSearch('')}
						value={search}
					/>

					<div className="flex flex-col gap-2">
						{inventories && inventories.items.length > 0 && (
							<div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-5">
								{inventories.items?.map((inventory) => (
									<div key={inventory.id}>
										<div className="block md:hidden">
											<MovieInventoryPoster
												posterCount={inventory.posterCount}
												posterSmallCount={inventory.posterSmallCount}
												title={inventory.title}
												posterPath={inventory.poster_path}
												height={250}
												width="100%"
											/>
										</div>

										<div className="hidden md:block">
											<MovieInventoryPoster
												posterCount={inventory.posterCount}
												posterSmallCount={inventory.posterSmallCount}
												title={inventory.title}
												posterPath={inventory.poster_path}
												height={350}
												width="100%"
											/>
										</div>
									</div>
								))}
							</div>
						)}

						{isLoadingInventories && (
							<div className="grid grid-cols-1 gap-4 md:grid-cols-5">
								<Skeleton height={513} width="100%" />
								<Skeleton height={513} width="100%" />
								<Skeleton height={513} width="100%" />
								<Skeleton height={513} width="100%" />
								<Skeleton height={513} width="100%" />
								<Skeleton height={513} width="100%" />
								<Skeleton height={513} width="100%" />
							</div>
						)}

						{inventories && inventories.items.length === 0 && (
							<div className="flex flex-col items-center justify-center gap-2">
								<Empty
									icon={<IconMovieCards />}
									title="Aucun résultat"
									description="Renseignez le titre exact ou essayez avec un autre film"
								/>
								<Button type="primary" onClick={() => setSearch('')}>
									Réinitialiser la recherche
								</Button>
							</div>
						)}

						{inventories && inventories.items && inventories.meta.totalItems > pageSize && (
							<div className="my-6 flex justify-end">
								<Pagination
									current={page}
									total={inventories.meta.totalItems}
									pageSize={pageSize}
									onChange={(pageChanged, pageSizeChanged) => {
										setPage(pageChanged);
										setPageSize(pageSizeChanged);
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</Container>
		</>
	);
};

export default Posters;
